export const CONFIG_VAR = {
    MY_VAR: 'my sample variabe',
    ENABLE_GRECAPTCHA: true,
    GRECAPTCHA_SITEKEY: '6LeVehsqAAAAACuTnqnNUDzxEspGuHvJ95-dvc5o',
    GMAP_API_KEY: 'AIzaSyAlKVyyg3GWDRW1F0Z6dVtKb-WNC93gUyk',
    GMAP_ADDRESS: "Almusbah Perfumes Office,Makkah International Business Center (MIBC),8TH Floor- Office No: 806",
    GMAP_LAT: 21.4115476,
    GMAP_LAN: 39.8657373,
    USE_GIFT_MESSAGE: true,
}
