import { PureComponent, createRef } from 'react';
import {
    MyAccountForgotPasswordComponent as SourceMyAccountForgotPasswordComponent,
} from 'SourceComponent/MyAccountForgotPassword/MyAccountForgotPassword.component';

import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import Form from 'Component/Form';
import { ValidationInputType } from 'Util/Validator/Config';

import ReCAPTCHA from "react-google-recaptcha";
import { setCaptchaToken, getCaptchaToken } from 'Util/Auth/CaptchaToken';
import { CONFIG_VAR } from './../../config/env';

// import './MyAccountForgotPassword.override.style';

/** @namespace AlmusbahblendPwa/Component/MyAccountForgotPassword/Component */
export class MyAccountForgotPasswordComponent extends SourceMyAccountForgotPasswordComponent {
    // TODO implement logic
    _reCaptchaRef = createRef();

    __construct(props) {
        super.__construct?.(props);

        this.generateGoogleRecaptchaToken = this.generateGoogleRecaptchaToken.bind(this);
    }

    renderForgotPasswordForm() {
        const { onForgotPasswordSuccess, onFormError } = this.props;

        return (
            <Form
              key="forgot-password"
              onSubmit={ this.generateGoogleRecaptchaToken }
              onError={ onFormError }
            >
                <Field
                  type={ FieldType.EMAIL }
                  label={ __('Email') }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      className: 'ForgotPassword-Input_type_email',
                      placeholder: __('Your email address'),
                      autoComplete: 'email',
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: ValidationInputType.EMAIL,
                  } }
                  addRequiredTag
                />
                { (CONFIG_VAR.ENABLE_GRECAPTCHA || false) && <ReCAPTCHA
                    style={{ display: "inline-block" }}
                    theme="dark"
                    size="invisible"
                    ref={this._reCaptchaRef}
                    onChange={this.handleChange}
                    sitekey={CONFIG_VAR.GRECAPTCHA_SITEKEY || ''}
                /> }
                <div block="MyAccountOverlay" elem="Buttons">
                    <button
                      block="Button"
                      type="submit"
                      mix={ { block: 'MyAccountOverlay', elem: 'ResetPassword' } }
                    >
                        { __('Send reset link') }
                    </button>
                </div>
            </Form>
        );
    }

    handleChange = value => {
        setCaptchaToken(value);
    };

    generateGoogleRecaptchaToken(form, fields) {
        const { onForgotPasswordSuccess, onFormError } = this.props;
        if ((CONFIG_VAR.ENABLE_GRECAPTCHA || false) && (getCaptchaToken() == (null || undefined))) {
            this._reCaptchaRef.current.executeAsync().then( async (value) => {
                onForgotPasswordSuccess(form);
                await this._reCaptchaRef.current.reset();
            });

        } else {
            onForgotPasswordSuccess(form);
        }
    }
}

export default MyAccountForgotPasswordComponent;
