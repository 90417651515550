/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Location } from 'history';

import { Router as ReactRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import ErrorHandler from 'Component/ErrorHandler';
import Loader from 'Component/Loader';
import Meta from 'Component/Meta';

import {
    PrintTypes,
} from 'Component/MyAccountOrderPrint/MyAccountOrderPrint.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import UrlRewrites from 'Route/UrlRewrites';
import { MyAccountTabs } from 'Type/Account.type';
import { ReactElement } from 'Type/Common.type';
import history from 'Util/History';
import { HistoryState } from 'Util/History/History.type';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';
import { appendWithStoreCode, isHomePageUrl } from 'Util/Url';

import {
    RouterAfterItemType,
    RouterBeforeItemType,
    RouterItemType,
    RouterSwitchItemType,
} from './Router.config';

import {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CookiePopup,
    CreateAccountPage,
    DemoNotice,
    Footer,
    ForgotPasswordPage,
    Header,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    OrderPrintPage,
    PasswordChangePage,
    ProductComparePage,
    RouterComponent as SourceRouterComponent,
    SearchPage,
    SendConfirmationPage,
    SomethingWentWrong,
    StyleGuidePage,
    WishlistShared,
    withStoreRegex,
} from 'SourceComponent/Router/Router.component';
import HyperpayStatus from 'Route/HyperpayStatus';
import TamaraStatus from 'Route/TamaraStatus';
import Status from 'Component/Status';
import OnepageCheckout from 'Route/OnepageCheckout';
import TrackShipment from 'Route/TrackShipment';

export {
    CartPage,
    Checkout,
    CmsPage,
    HomePage,
    MyAccount,
    PasswordChangePage,
    SearchPage,
    SendConfirmationPage,
    ConfirmAccountPage,
    MenuPage,
    WishlistShared,
    ContactPage,
    ProductComparePage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    StyleGuidePage,
    OrderPrintPage,
    Header,
    NavigationTabs,
    Footer,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    CookiePopup,
    DemoNotice,
    SomethingWentWrong,
    Breadcrumbs,
    withStoreRegex,
};

/** @namespace AlmusbahblendPwa/Component/Router/Component */
export class RouterComponent extends SourceRouterComponent {
    // TODO implement logic
    [RouterItemType.SWITCH_ITEMS_TYPE] = [
        {
            component: <Route path={ withStoreRegex('/') } exact render={ ({ match }) => <HomePage match={ match } currentUrl={ this.props.currentUrl } /> } />,
            position: 10,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: <Route path={ withStoreRegex('/search/:query/') } render={ ({ match }) => <SearchPage match={ match } /> } />,
            position: 25,
            name: RouterSwitchItemType.SEARCH,
        },
        {
            component: <Route path={ withStoreRegex('/page') } render={ ({ match }) => <CmsPage match={ match } currentUrl={ this.props.currentUrl } /> } />,
            position: 40,
            name: RouterSwitchItemType.CMS_PAGE,
        },
        {
            component: <Route path={ withStoreRegex('/cart') } exact render={ () => <CartPage /> } />,
            position: 50,
            name: RouterSwitchItemType.CART,
        },
        // {
        //     component: <Route path={ withStoreRegex('/checkout/:step?') } render={ ({ match }) => <Checkout match={ match } /> } />,
        //     position: 55,
        //     name: RouterSwitchItemType.CHECKOUT,
        // },
        {
            // @ts-ignore Due to problems with extended components
            component: <Route path={ withStoreRegex('/customer/account/createPassword/') } render={ () => <PasswordChangePage /> } />,
            position: 60,
            name: RouterSwitchItemType.CHANGE_PASSWORD,
        },
        {
            // @ts-ignore Due to problems with extended components
            component: <Route path={ withStoreRegex('/customer/account/create/') } render={ () => <CreateAccountPage /> } />,
            position: 61,
            name: RouterSwitchItemType.CREATE_ACCOUNT,
        },
        {
            // @ts-ignore Due to problems with extended components
            component: <Route path={ withStoreRegex('/customer/account/login/') } render={ () => <LoginAccountPage /> } />,
            position: 62,
            name: RouterSwitchItemType.LOGIN,
        },
        {
            // @ts-ignore Due to problems with extended components
            component: <Route path={ withStoreRegex('/customer/account/forgotpassword/') } render={ () => <ForgotPasswordPage /> } />,
            position: 63,
            name: RouterSwitchItemType.ACCOUNT_FORGOT_PASSWORD,
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/confirmation') } render={ () => <SendConfirmationPage /> } />,
            position: 64,
            name: RouterSwitchItemType.CONFIRM_ACCOUNT,
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/confirm') } render={ () => <ConfirmAccountPage /> } />,
            position: 65,
            name: RouterSwitchItemType.CONFIRM_ACCOUNT,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/view/order_id/:orderId?') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.MY_ORDERS } /> } />,
            position: 70,
            name: RouterSwitchItemType.MY_ACCOUNT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/history') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.MY_ORDERS } /> } />,
            position: 71,
            name: RouterSwitchItemType.MY_ACCOUNT_ORDERS,
        },
        {
            component: <Route path={ withStoreRegex('/downloadable/customer/products') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.MY_DOWNLOADABLE } /> } />,
            position: 72,
            name: RouterSwitchItemType.MY_ACCOUNT_DOWNLOADABLE,
        },
        {
            component: <Route path={ withStoreRegex('/wishlist') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.MY_WISHLIST } /> } />,
            position: 73,
            name: RouterSwitchItemType.MY_ACCOUNT_WISHLIST,
        },
        {
            component: <Route path={ withStoreRegex('/customer/address') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.ADDRESS_BOOK } /> } />,
            position: 74,
            name: RouterSwitchItemType.MY_ACCOUNT_ADDRESS,
        },
        {
            component: <Route path={ withStoreRegex('/newsletter/manage') } render={ ({ match }) => <MyAccount match={ match } selectedTab={ MyAccountTabs.NEWSLETTER_SUBSCRIPTION } /> } />,
            position: 75,
            name: RouterSwitchItemType.MY_ACCOUNT_NEWSLETTER,
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/:tab?') } render={ ({ match }) => <MyAccount match={ match } /> } />,
            position: 76,
            name: RouterSwitchItemType.MY_ACCOUNT,
        },
        {
            component: <Route path={ withStoreRegex('/menu') } render={ () => <MenuPage /> } />,
            position: 80,
            name: RouterSwitchItemType.MENU,
        },
        {
            component: <Route path={ withStoreRegex('/wishlist/shared/:code') } render={ ({ match }) => <WishlistShared match={ match } /> } />,
            position: 81,
            name: RouterSwitchItemType.SHARED_WISHLIST,
        },
        {
            component: <Route path={ withStoreRegex('/contact') } render={ () => <ContactPage /> } />,
            position: 82,
            name: RouterSwitchItemType.CONTACT_PAGE,
        },
        {
            component: <Route path={ withStoreRegex('/compare') } render={ () => <ProductComparePage /> } />,
            position: 83,
            name: RouterSwitchItemType.COMPARE,
        },
        {
            component: <Route path={ withStoreRegex('/styleguide') } render={ () => <StyleGuidePage /> } />,
            position: 84,
            name: RouterSwitchItemType.STYLE_GUIDE,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/print/order_id/:orderId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_ORDER } /> } />,
            position: 90,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printInvoice/order_id/:orderId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_ALL_INVOICES } /> } />,
            position: 91,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printShipment/order_id/:orderId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_ALL_SHIPMENT } /> } />,
            position: 92,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printCreditmemo/order_id/:orderId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_ALL_REFUNDS } /> } />,
            position: 93,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printInvoice/invoice_id/:invoiceId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_INVOICE } /> } />,
            position: 94,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printShipment/shipment_id/:shipmentId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_SHIPMENT } /> } />,
            position: 95,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printCreditmemo/creditmemo_id/:refundId?') } render={ ({ match }) => <OrderPrintPage match={ match } orderPrintRequest={ PrintTypes.PRINT_REFUND } /> } />,
            position: 95,
            name: PrintTypes.PRINT_ORDER,
        },
        {
            component: <Route path={ withStoreRegex('/hyperpay/status') } render={ ({ match }) => <HyperpayStatus match={ match } /> } />,
            position: 97,
            name: 'Hyperpay Status',
        },
        {
            component: <Route path={ withStoreRegex('/tamara/payment/:order_id/:status') } render={ ({ match }) => <TamaraStatus match={ match } /> } />,
            position: 98,
            name: 'Tamara Status',
        },
        {
            component: <Route path={ withStoreRegex('/order-success/:orderId/:orderStatus') } render={ ({ match }) => <Status match={ match } /> } />,
            position: 99,
            name: 'Order Success',
        },
        {
            component: <Route path={ withStoreRegex('/order-failure/:orderId/:orderStatus') } render={ ({ match }) => <Status match={ match } /> } />,
            position: 99,
            name: 'Order Failure',
        },
        {
            component: <Route path={ withStoreRegex('/checkout/:step?') } render={ ({ match }) => <OnepageCheckout match={ match } /> } />,
            position: 100,
            name: 'ONEPAGE_CHECKOUT',
        },
        {
            component: <Route path={ withStoreRegex('/shipping/tracking/popup') } render={ ({ match }) => <TrackShipment match={ match } /> } />,
            position: 100,
            name: 'Shipment Tracking',
        },
        {
            component: <Route render={ ({ match }) => <UrlRewrites match={ match } location={ location } /> } />,
            position: 1000,
            name: RouterSwitchItemType.URL_REWRITES,
        },
    ];
}

export default RouterComponent;
