import { connect } from 'react-redux';

import {
    CheckoutAddressBookContainer as SourceCheckoutAddressBookContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/CheckoutAddressBook/CheckoutAddressBook.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Component/CheckoutAddressBook/Container */
export class CheckoutAddressBookContainer extends SourceCheckoutAddressBookContainer {
    // TODO implement logic

    containerProps() {
        const {
            customer,
            onShippingEstimationFieldsChange,
            selectedDeliveryAddress,
            isBilling,
            isSubmitted,
            is_virtual,
        } = this.props;
        const { selectedAddressId } = this.state;

        return {
            customer,
            onShippingEstimationFieldsChange,
            selectedDeliveryAddress,
            isBilling,
            selectedAddressId,
            isSubmitted,
            is_virtual,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddressBookContainer);
