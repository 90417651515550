import {
    MetaComponent as SourceMetaComponent,
} from 'SourceComponent/Meta/Meta.component';

import { Fragment } from 'react';

/** @namespace AlmusbahblendPwa/Component/Meta/Component */
export class MetaComponent extends SourceMetaComponent {
    // TODO implement logic
    renderGtmTag() {
        return (
            <script
                    dangerouslySetInnerHTML={{
                        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-PH7WV928');`
                    }}
                />
        );
    }

    renderTamaraWidget() {

        // const scriptWidget = document.createElement('script');
        // scriptWidget.src = `https://cdn.tamara.co/widget-v2/tamara-widget.js`;
        // // script.src = tamaraInstalmentWidgetUrl ;
        // scriptWidget.async = true;
        
        // document.head.appendChild(scriptWidget);
        // return (
        //     <script src="https://cdn.tamara.co/widget-v2/tamara-widget.js"></script>
        // );
    }


    renderMeta() {
        const { metadata } = this.props;

        return [
            <Fragment key="meta-tags">
                { this.renderTitle() }
                { this.renderCanonical() }
                { this.renderGtmTag() }
                { this.renderTamaraWidget() }
                { metadata.map((tag) => {
                    const {
                        name,
                        property = null,
                        content,
                    } = tag;

                    return (
                        <meta
                          key={ name || property }
                          name={ name }
                          content={ content }
                        />
                    );
                }) }
            </Fragment>,
        ];
    }
}

export default MetaComponent;
