/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { lazy, PureComponent, Suspense } from 'react';

import CheckoutGuestForm from 'Component/CheckoutGuestForm';
import ContentWrapper from 'Component/ContentWrapper';
import { Page } from 'Component/Header/Header.config';
import Loader from 'Component/Loader';
import { scrollToTop } from 'Util/Browser';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import Form from 'Component/Form';
import {
    CHECKOUT_URL_REGEX,
    CheckoutSteps,
    CheckoutStepUrl,
} from './OnepageCheckout.config';
import './OnepageCheckout.style';
import ProceedPayment from 'Component/ProceedPayment';
import OrderQuery from 'Query/Order.query';
import { fetchQuery } from 'Util/Request/Query';
import agtm from '../../gtm/tags';
import { COD_PAYMENT } from 'Component/OnepageCheckoutBilling/OnepageCheckoutBilling.component';
import GiftMessage from 'Component/GiftMessage';

export const HYPERPAY_CARDS = 'HyperPay_Cards';
export const HYPERPAY_MADA = 'HyperPay_Mada';
export const HYPERPAY_APPLEPAY = 'HyperPay_ApplePay';
export const TAMARA_PAYMENT = 'tamara_pay_by_instalments';

export const CartCoupon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-info" */
    'Component/CartCoupon'
));

export const CmsBlock = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-info" */
    'Component/CmsBlock'
));

export const CheckoutOrderSummary = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-info" */
    'Component/CheckoutOrderSummary'
));

export const OnepageCheckoutBilling = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-billing" */
    'Component/OnepageCheckoutBilling'
));

export const OnepageCheckoutShipping = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-shipping" */
    'Component/OnepageCheckoutShipping'
));

export const CheckoutSuccess = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-success" */
    'Component/CheckoutSuccess'
));

export const ExpandableContent = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-info" */
    'Component/ExpandableContent'
));
/** @namespace AlmusbahblendPwa/Route/OnepageCheckout/Component */
export class OnepageCheckoutComponent extends PureComponent {
    static defaultProps = {
        paymentTotals: undefined,
        selectedStoreAddress: undefined,
        isLoading: false,
    };

    state = {
        isOrderButtonVisible: true,
        isOrderButtonEnabled: true,
        isTACAccepted: false,
        isOtpValidated: false,
    };

    stepMap= {
        [CheckoutSteps.CHECKOUT_STEP]: {
            number: 1,
            title: __('Checkout'),
            url: '',
            areTotalsVisible: true,
            showGuestEmail: true,
            showGiftMessage: true,
        },
        [CheckoutSteps.PAYMENT_STEP]: {
            title: __('Proceed Payment'),
            url: '/order-payment',
            render: this.renderPaymentStep.bind(this),
            areTotalsVisible: false,
            showGuestEmail: false,
            showGiftMessage: false,
        },
        [CheckoutSteps.DETAILS_STEP]: {
            title: __('Thank you for your purchase!'),
            mobileTitle: __('Order details'),
            url: '/order-success',
            render: this.renderDetailsStep.bind(this),
            areTotalsVisible: false,
            showGuestEmail: false,
            showGiftMessage: false,
        },
    };

    componentDidMount() {
        this.updateHeader();
        this.updateStepURL(true);

        const { termsAreEnabled } = this.props;

        if (!termsAreEnabled) {
            this.setState({ isOrderButtonEnabled: true });
        }
    }

    componentDidUpdate(prevProps) {
        const { checkoutStep } = this.props;
        const { checkoutStep: prevCheckoutStep } = prevProps;

        if (checkoutStep !== prevCheckoutStep) {
            this.updateHeader();
            this.updateStep();
        }
    }

    containerFunctions = {
        setTACAccepted: this.setTACAccepted.bind(this),
        setOrderButtonEnableStatus: this.setOrderButtonEnableStatus.bind(this),
        validateMobileOtp: this.validateMobileOtp.bind(this),
    };

    updateHeader() {
        const { setHeaderState, checkoutStep, goBack } = this.props;
        const { mobileTitle, title } = this.stepMap[checkoutStep];

        setHeaderState({
            name: checkoutStep === CheckoutSteps.DETAILS_STEP
                ? Page.CHECKOUT_SUCCESS
                : Page.CHECKOUT,
            title: mobileTitle || title,
            onBackClick: () => goBack(),
        });
    }

    updateStepURL(isMounting = false) {
        const { checkoutStep, isCartLoading, orderID } = this.props;
        const { url } = this.stepMap[checkoutStep];
        const { location: { pathname = '' } } = history;

        if (isCartLoading && orderID && checkoutStep == CheckoutSteps.DETAILS_STEP) {
            history.push(appendWithStoreCode(CheckoutStepUrl.CHECKOUT_SUCCESS_URL));

        } else if (!(isCartLoading && pathname.match(CHECKOUT_URL_REGEX))) {
            if (isMounting) {
                history.replace(appendWithStoreCode(`${ CheckoutStepUrl.CHECKOUT_URL }${ url }`));
            } else {
                history.push(appendWithStoreCode(`${ CheckoutStepUrl.CHECKOUT_URL }${ url }`));
            }
        } 
    }

    updateStep() {
        this.updateStepURL();
        scrollToTop({ behavior: 'smooth' });
    }

    renderTitle() {
        const { checkoutStep, totals: { is_virtual }, isMobile } = this.props;
        const { title = '', number } = this.stepMap[checkoutStep];

        if (isMobile) {
            return null;
        }

        return (
            <div block="Checkout" elem="Header">
                <div block="Checkout" elem="Title">{ title }</div>
            </div>
        );
    }

    renderPaymentStep() {
        const {
            setLoading,
            setDetailsStep,
            shippingAddress,
            paymentMethods = [],
            paymentMethod,
            savePaymentInformation,
            selectedShippingMethod,
            onChangeEmailRequired,
            selectedPaymentMethod,
            orderID,
        } = this.props;
        // console.log("this.props in checkout component: ", this.props);
        // console.log("selectedPaymentMethod in checkout component: ", paymentMethod);
        return (
            // <div>Coming here to checkout component</div>
             <Suspense fallback={ <Loader /> }>
                 <ProceedPayment
                   setLoading={ setLoading }
                   paymentMethods={ paymentMethods }
                   setDetailsStep={ setDetailsStep }
                   shippingAddress={ shippingAddress }
                   savePaymentInformation={ savePaymentInformation }
                   selectedShippingMethod={ selectedShippingMethod }
                   onChangeEmailRequired={ onChangeEmailRequired }
                   selectedPaymentMethod={ paymentMethod }
                   orderID={ orderID }
                 />
             </Suspense>
        );
    }

    renderGuestForm() {
        const {
            checkoutStep,
            isCreateUser,
            onEmailChange,
            onCreateUserChange,
            onPasswordChange,
            isGuestEmailSaved,
            isSignedIn,
            isVisibleEmailRequired,
        } = this.props;

        const { showGuestEmail } = this.stepMap[checkoutStep];

        if (isSignedIn || !showGuestEmail) {
            return null;
        }

        return (
             <CheckoutGuestForm
               isCreateUser={ isCreateUser }
               onEmailChange={ onEmailChange }
               onCreateUserChange={ onCreateUserChange }
               onPasswordChange={ onPasswordChange }
               isGuestEmailSaved={ false }
               isVisibleEmailRequired={ isVisibleEmailRequired }
               showGuestEmail={ showGuestEmail }
             />
        );
    }

    containerProps() {
        const {
            cartTotalSubPrice,
            isEmailAvailable,
            isMobile,
            setHeaderState,
            totals,
            isInStoreActivated,
            isSignedIn,
            isCartLoading,
            billingAddress,
            checkoutStep,
            checkoutTotals,
            email,
            estimateAddress,
            isCreateUser,
            isDeliveryOptionsLoading,
            isGuestEmailSaved,
            isLoading,
            orderID,
            paymentMethods = [],
            paymentTotals,
            selectedShippingMethod,
            shippingAddress,
            currentDeliveryAddress,
            shippingMethods,
            selectedStoreAddress,
            isPickInStoreMethodSelected,
            isVisibleEmailRequired,
            isSameAsShipping, 
            paymentMethod,
            setLoading,
            setDetailsStep,
            savePaymentInformation,
            saveAddressInformation,
            onShippingEstimationFieldsChange,
            onEmailChange,
            onCreateUserChange,
            onPasswordChange,
            goBack,
            handleSelectDeliveryMethod,
            onStoreSelect,
            onShippingMethodSelect,
            onChangeEmailRequired,
            onAddressSelect,
            onSameAsShippingChange,
            onPaymentMethodSelect,
            showPopup,
            onOnepageCheckoutPageSuccess,
            onOnepageCheckoutPageError,
            selectedDeliveryAddress,
        } = this.props;

        const {
            isOtpValidated,
        } = this.state;

        return {
            billingAddress,
            cartTotalSubPrice,
            checkoutStep,
            checkoutTotals,
            email,
            estimateAddress,
            isCreateUser,
            isDeliveryOptionsLoading,
            isEmailAvailable,
            isGuestEmailSaved,
            isInStoreActivated,
            isSignedIn,
            isLoading,
            isMobile,
            orderID,
            paymentMethods,
            paymentTotals,
            selectedShippingMethod,
            setHeaderState,
            shippingAddress,
            currentDeliveryAddress,
            shippingMethods,
            totals,
            selectedStoreAddress,
            isPickInStoreMethodSelected,
            isCartLoading,
            isVisibleEmailRequired,
            isSameAsShipping, 
            paymentMethod,
            setLoading,
            setDetailsStep,
            savePaymentInformation,
            saveAddressInformation,
            onShippingEstimationFieldsChange,
            onEmailChange,
            onCreateUserChange,
            onPasswordChange,
            goBack,
            handleSelectDeliveryMethod,
            onStoreSelect,
            onShippingMethodSelect,
            onChangeEmailRequired,
            onAddressSelect,
            onSameAsShippingChange,
            onPaymentMethodSelect,
            showPopup,
            onOnepageCheckoutPageSuccess,
            onOnepageCheckoutPageError,
            selectedDeliveryAddress,
            isOtpValidated,
        };
    }

    renderOnepageShippingStep() {

        // console.log("Selected Shipping Method:", selectedShippingMethod);

        return (
             <Suspense fallback={ <Loader /> }>
                 <OnepageCheckoutShipping
                   { ...this.containerProps() }
                 />
             </Suspense>
        );
    }

    renderOnepageBillingStep() {

        return (
            <div block="BillingDetails">
                <Suspense fallback={ <Loader /> }>
                    <OnepageCheckoutBilling 
                        { ...this.containerProps() } 
                        { ...this.containerFunctions }
                    />
                </Suspense>
                { this.renderActions() }
             </div>
        );
    }

    renderDetailsStep() {
        const {
            orderID,
            isEmailAvailable,
            email,
            billingAddress: {
                firstname,
                lastname,
            } = {},
            totals,
            checkoutStep,
        } = this.props;
        
        const { pathname = appendWithStoreCode('/') } = location;

        // Call Gtm for CHeckout Success
        if (checkoutStep == CheckoutSteps.DETAILS_STEP && pathname.match("/success")) {
            this.gtmCheckoutSuccess(orderID);
        }
        
        
        return (
             <Suspense fallback={ <Loader /> }>
                 <CheckoutSuccess
                   email={ email }
                   firstName={ firstname || '' }
                   lastName={ lastname || '' }
                   isEmailAvailable={ isEmailAvailable }
                   orderID={ orderID }
                 />
             </Suspense>
        );
    }

    async gtmCheckoutSuccess(orderNumber) {
        const orderDataRes = await fetchQuery(OrderQuery.getOrderData('', orderNumber));
        const orderData = orderDataRes.getorder;
        if(orderData.id) {
            for(let i in orderData.items){
                orderData.items[i]['prices'] = {
                    regular_price: {value: orderData.items[i].regular_price},
                    price: {value: orderData.items[i].price},
                    final_price: {value: orderData.items[i].final_price},
                    total_item_discount: orderData.items[i].total_item_discount,
                }
            }
            let gtmObj = {
                products:orderData.items,
                summary: {
                    coupon_code: orderData.coupon_code,
                    applied_rule_ids: orderData.applied_rule_ids,
                    discount: { amount: { value: orderData.discount}, percent: {value: 0}},
                    subtotal_including_tax: {value: orderData.subtotal_including_tax},
                    applied_taxes: [{amount: {value: orderData.tax_amount}}],
                    shipping_amount_incl_tax: {value: orderData.shipping_incl_tax},
                    cod_fee_incl_tax: {value: orderData.cod_fee_incl_tax},
                },
                shipping:orderData.address,
                payment: orderData.payment_method,
                shipping_method: orderData.shipping_method,
            }
            const event = 'completed';
            var date_time = `${Math.floor(new Date() / 1000)}`
            let contentCategory = 'Purchase'
            let contentName = 'Purchase'
            let contentIds = []
            let contents = []
            for(let i in orderData.items){
                contentIds.push(orderData.items[i].sku)
                contents.push({id:orderData.items[i].sku , quantity:orderData.items[i].salable_qty?orderData.items[i].salable_qty:1})
            }
            let num_items = contentIds.length
            gtmObj['customerInfo'] = orderData.address || {};
            agtm.checkoutEvent(date_time, gtmObj, event, 'OrderConfirmationPage', 'en' ,{date_time ,contentIds ,contents , num_items ,contentName , contentCategory});
        }
    }

    renderDiscountCode() {
        const {
            checkoutStep,
            totals: {
                items = [],
                prices: {
                    coupon_code,
                } = {},
            },
        } = this.props;

        if (!items || items.length < 1) {
            return null;
        }

        return (
             <ExpandableContent
               heading={ __('Have a discount code?') }
               mix={ { block: 'Checkout', elem: 'Discount' } }
               isArrow
             >
                 <CartCoupon couponCode={ coupon_code } />
             </ExpandableContent>
        );
    }

    renderStep() {
        const { checkoutStep } = this.props;
        const { render } = this.stepMap[checkoutStep];

        if (render) {
            return render();
        }

        return null;
    }

    renderLoader() {
        const { isLoading } = this.props;

        return <Loader isLoading={ isLoading } />;
    }

    renderFullPageLoader() {
        return (
             <main block="Checkout" elem="FullPageLoader">
                 <Loader isLoading />
             </main>
        );
    }

    renderSummary(showOnMobile = false) {
        const {
            checkoutTotals,
            checkoutStep,
            paymentTotals,
            isMobile,
        } = this.props;
        const { areTotalsVisible } = this.stepMap[checkoutStep];

        if (!areTotalsVisible || (showOnMobile && !isMobile) || (!showOnMobile && isMobile)) {
            return null;
        }

        return (
            <div block="CheckoutSummary">
                 <CheckoutOrderSummary
                   checkoutStep={ checkoutStep }
                   totals={ checkoutTotals }
                   paymentTotals={ paymentTotals }
                   isExpandable={ isMobile }
                   showItems
                 />
                 {/* { !showOnMobile && this.renderDiscountCode() } */}
            </div>
        );
    }

    renderGiftMessage() {
        const { isSignedIn, checkoutStep } = this.props;
        const { showGiftMessage } = this.stepMap[checkoutStep];

        if (!showGiftMessage) {
            return null;
        }

        return (
            <Suspense fallback={ <Loader /> }>
                <GiftMessage isSignedIn = { isSignedIn } />
            </Suspense>
        );
    }

    renderPromo(showOnMobile = false) {
        const { checkoutStep, isMobile } = this.props;
        const isBilling = true;

        if ((!showOnMobile && isMobile) || checkoutStep === CheckoutSteps.DETAILS_STEP) {
            return null;
        }

        const {
            checkout_content: {
                [isBilling ? 'checkout_billing_cms' : 'checkout_shipping_cms']: promo,
            } = {},
        } = window.contentConfiguration || {};

        if (!promo) {
            return null;
        }

        return (
             <div block="Checkout" elem="Promo">
                 <CmsBlock identifier={ promo } />
             </div>
        );
    }

    renderStoreInPickUpMethod() {
        const {
            isPickInStoreMethodSelected,
            handleSelectDeliveryMethod,
            checkoutStep,
            isInStoreActivated,
            shippingMethods,
            totals: {
                is_in_store_pickup_available: isInStorePickupAvailable,
            },
        } = this.props;

        if (!isInStoreActivated) {
            return null;
        }

        if (!isInStorePickupAvailable) {
            return null;
        }

        return (
             <div
               block="Checkout"
               elem="DeliverySelect"
             >
                 <button
                   block="Checkout"
                   elem="ShippingButton"
                   mix={ { block: 'Button', mods: { isHollow: !isPickInStoreMethodSelected } } }
                   type="button"
                   disabled={ !isPickInStoreMethodSelected }
                   onClick={ handleSelectDeliveryMethod }
                 >
                    { __('Shipping') }
                 </button>
                 <button
                   block="Checkout"
                   elem="PickInStore"
                   mix={ { block: 'Button', mods: { isHollow: isPickInStoreMethodSelected } } }
                   type="button"
                   disabled={ isPickInStoreMethodSelected || shippingMethods?.length < 1 }
                   onClick={ handleSelectDeliveryMethod }
                 >
                     { __('Pick in Store') }
                 </button>
             </div>
        );
    }

    setOrderButtonEnableStatus(isOrderButtonEnabled) {
        this.setState({ isOrderButtonEnabled });
    }

    setTACAccepted(isTACAccepted) {
        this.setState({ isTACAccepted });
    }

    validateMobileOtp(resp) {
        this.setState({ isOtpValidated: resp});
    }

    renderActions() {
        const { paymentMethod } = this.props;
        const { selectedShippingMethod } = this.props;

        const {
            isOrderButtonVisible,
            isOrderButtonEnabled,
            isTACAccepted,
            isOtpValidated,
        } = this.state;

        const { termsAreEnabled } = this.props;

        if (!isOrderButtonVisible) {
            return null;
        }

        // if terms and conditions are enabled, validate for acceptance
        const isDisabled = termsAreEnabled
            ? !isOrderButtonEnabled || !isTACAccepted || !paymentMethod || !selectedShippingMethod || (!isOtpValidated && paymentMethod == COD_PAYMENT)
            : !isOrderButtonEnabled;

        if (paymentMethod == HYPERPAY_CARDS || paymentMethod == HYPERPAY_APPLEPAY) {
            return (
                <div block="OnepageCheckout" elem="StickyButtonWrapper">
                    <button
                      type="submit"
                      block="Button"
                      disabled={ isDisabled }
                      mix={ { block: 'OnepageCheckoutBilling', elem: 'Button' } }
                    >
                        { __('Proceed Payment') }
                    </button>
                </div>
            );
        } else {
            return (
                <div block="OnepageCheckout" elem="StickyButtonWrapper">
                    <button
                      type="submit"
                      block="Button"
                      disabled={ isDisabled }
                      mix={ { block: 'OnepageCheckout', elem: 'Button' } }
                    >
                        { __('Complete order') }
                    </button>
                </div>
            );
        }
    }

    renderContent() {
        const {
            onOnepageCheckoutPageSuccess,
            onOnepageCheckoutProceedPayment,
            onOnepageCheckoutPageError,
            checkoutStep,
            paymentMethod,
        } = this.props;

        const { render } = this.stepMap[checkoutStep];

        if (render) {
            return render();
        }

        // if (paymentMethod == HYPERPAY_CARDS || paymentMethod == HYPERPAY_APPLEPAY) {
        //     return (
        //         <Form
        //             attr={ {
        //                 id: CheckoutSteps.CHECKOUT_STEP,
        //             } }
        //             onSubmit={ onOnepageCheckoutProceedPayment }
        //             onError={ onOnepageCheckoutPageError }
        //         >
        //             <div block="OnepageCheckoutForm">
        //                 { this.renderOnepageShippingStep() }
        //                 { this.renderOnepageBillingStep() }
        //             </div>
        //         </Form>
        //     );
        // } else {
            return (
                <Form
                    attr={ {
                        id: CheckoutSteps.CHECKOUT_STEP,
                    } }
                    onSubmit={ onOnepageCheckoutPageSuccess }
                    onError={ onOnepageCheckoutPageError }
                >
                    <div block="OnepageCheckoutForm">
                        { this.renderOnepageShippingStep() }
                        { this.renderOnepageBillingStep() }
                    </div>
                </Form>
            );
        // }
    }

    render() {
        const { totals, checkoutStep } = this.props;
        // console.log("this.props:-- ", this.props);
       
        if (totals.items && totals.items.length < 1 && checkoutStep !== CheckoutSteps.DETAILS_STEP && checkoutStep !== CheckoutSteps.PAYMENT_STEP) {
            return this.renderFullPageLoader();
        }

        return (
             <main block="OnepageCheckout">
                 <ContentWrapper
                   wrapperMix={ { block: 'OnepageCheckout', elem: 'Wrapper' } }
                   label={ __('Checkout page') }
                 >
                     <div>
                         <div block="OnepageCheckout" elem="FullStep">
                             { this.renderTitle() }
                             { this.renderStoreInPickUpMethod() }
                             { this.renderGuestForm() }
                             {/* { this.renderStep() } */}
                             { this.renderContent() }
                             { this.renderLoader() }
                             { this.renderSummary(true) }
                         </div>
                     </div>
                     <div>
                         <Suspense fallback={ <Loader /> }>
                             { this.renderSummary() }
                             { this.renderPromo() }
                             { this.renderGiftMessage() }
                         </Suspense>
                     </div>
                 </ContentWrapper>
             </main>
        );
    }
}

export default OnepageCheckoutComponent;
