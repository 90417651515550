import {
    CheckoutQuery as SourceCheckoutQuery,
} from 'SourceQuery/Checkout.query';

import { Field, Mutation, Query } from '@tilework/opus';
import { isSignedIn } from 'Util/Auth/IsSignedIn';

/** @namespace AlmusbahblendPwa/Query/CheckoutQuery */
export class CheckoutQuery extends SourceCheckoutQuery {
    // TODO implement logic
    applyPaymentMethodOnCheckoutMutation(
        input,
        guestCartId,
    ) {
        // console.log("setPaymentMethodOnCheckout input: ", input);
        const mutation = new Mutation('setPaymentMethodOnCheckout')
            .addArgument('input', 'SetPaymentMethodOnCheckoutInput', input)
            .addFieldList(this._getSetPaymentMethodOnCheckoutFields());

        this._addGuestCartId(guestCartId, mutation);

        return mutation;
    }

    _getSetPaymentMethodOnCheckoutFields() {
        return [
            'selected_payment_method',
            this._getTotalsField(),
        ];
    }

    _addGuestCartId(
        guestCartId,
        mutation,
    ) {
        if (guestCartId && !isSignedIn()) {
            mutation.addArgument('guestCartId', 'String!', guestCartId);
        }
    }

    _getTotalsFields() {
        return [
            new Field('subtotal'),
            new Field('tax_amount'),
            new Field('base_grand_total'),
            new Field('grand_total'),
            new Field('discount_amount'),
            new Field('shipping_amount'),
            new Field('subtotal_incl_tax'),
            new Field('shipping_incl_tax'),
            new Field('cod_fee_excl_tax'),
            new Field('cod_fee_incl_tax'),
            new Field('quote_currency_code'),
            new Field('shipping_tax_amount'),
            new Field('subtotal_with_discount'),
            new Field('shipping_discount_amount'),
            this._getTotalItemField(),
        ];
    }

}

export default new CheckoutQuery();
