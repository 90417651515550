import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer,
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';
import { SignInOptions } from 'Query/MyAccount.type';
// import { CheckoutStepUrl } from 'Route/Checkout/Checkout.config';
import { CheckoutStepUrl } from 'Route/OnepageCheckout/OnepageCheckout.config';
import MyAccountDispatcher from 'Store/MyAccount/MyAccount.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { NetworkError, ReactElement } from 'Type/Common.type';
import { noopFn } from 'Util/Common';
import { deleteCaptchaToken } from 'Util/Auth/CaptchaToken';
import transformToNameValuePair from 'Util/Form/Transform';
import history from 'Util/History';
import { getErrorMessage } from 'Util/Request/Error';
import { RootState } from 'Util/Store/Store.type';
import { appendWithStoreCode } from 'Util/Url';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Component/MyAccountSignIn/Container */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    // TODO implement logic
    async onSignInSuccess(form, fields) {
        const {
            signIn,
            showNotification,
            onSignIn,
            setLoadingState,
            totals: { is_virtual },
            isCheckout,
        } = this.props;

        const {
            isSignIn,
        } = this.state;

        setLoadingState(true);
        const fieldPairs = transformToNameValuePair(fields);

        if (!isSignIn) {
            this.setState({ isSignIn: true });

            try {
                await signIn(fieldPairs);
                onSignIn();
            } catch (error) {
                showNotification(NotificationType.ERROR, getErrorMessage(error));
                this.setState({ isSignIn: false });
            } finally {
                setLoadingState(false);
                deleteCaptchaToken();
            }
        }

        setLoadingState(false);
        if (isCheckout) {
            history.push({ pathname: appendWithStoreCode(CheckoutStepUrl.CHECKOUT_URL) });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
