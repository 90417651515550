/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/**
 * Slider Query
 * @class Slider
 * @namespace Query/TamaraQuery */
export class TamaraQuery {
    getTamaraPaymentTypes(countryCode, currencyCode, orderValue, phoneNumber) {
        console.log("received orderValue for Tamara options: ", orderValue);
        return new Field('getpaymentoptions')
        .addArgument('countryCode', 'String', countryCode)
        .addArgument('currencyCode', 'String', currencyCode)
        .addArgument('orderValue', 'String', orderValue)
        .addArgument('phoneNumber', 'String', phoneNumber)
        .addFieldList(this._getOrderFields());
    }

    _getOrderFields() {
        return [
            'method',
            'paymentValue',
            'no_of_installments',
            'first_installment_value',
        ];
    }

    getTamaraPaymentUrl(orderId) {
        console.log("received orderId for placetamaraorder: ", orderId);
        return new Field('placetamaraorder')
        .addArgument('orderId', 'String', orderId)
        .addFieldList(this._getTamaraUrlFields());
    }

    _getTamaraUrlFields() {
        return [
            'success',
            'orderId',
            'redirectUrl',
            'error',
        ];
    }

    setPaymentMethodAndPlaceOrderMutation(input) {
        console.log("setPaymentMethodAndPlaceOrderMutation input: ", input);
        return new Field('setPaymentMethodAndPlaceOrderForTamara')
        .addArgument('input', 'SetPaymentMethodAndPlaceOrderInputTamara', input)
        .addField(this._getOrderField());
    }

    _getOrderField() {
        return new Field('order')
            .addFieldList(['order_id', 'order_number']);
    }

    updateTamaraPaymentStatus(input) {
        console.log("updateTamaraPaymentStatus input: ", input);
        return new Field('tamarapaymentresponse')
        .addArgument('input', 'TamaraPaymentInput', input)
        .addFieldList(this._getTamaraOrderField());
    }

    _getTamaraOrderField() {
        return [
            'orderNumber',
            'orderId',
            'status',
            'message',
            'email',
            'firstname',
            'lastname'
        ];
    }

    getTamaraPaymentConfig() {
        return new Field('gettamarapaymentconfig')
        .addArgument('countryCode', 'String', 'SA')
        .addFieldList(this._getTamaraConfigData());
    }

    _getTamaraConfigData() {
        return [
            'tamaraLocale',
            'tamaraCurrency',
            'tamaraCountry',
            'tamaraPublicKey',
            'tamaraProductWidgetUrl',
            'tamaraInstalmentWidgetUrl',
            'codOtpEnable'
        ];
    }
}

export default new TamaraQuery();
