import {
    ImageContainer as SourceImageContainer,
} from 'SourceComponent/Image/Image.container';

/** @namespace AlmusbahblendPwa/Component/Image/Container */
export class ImageContainer extends SourceImageContainer {
    // TODO implement logic
}

export default ImageContainer;
