import {
    FieldContainer as SourceFieldContainer,
} from 'SourceComponent/Field/Field.container';

/** @namespace AlmusbahblendPwa/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    // TODO implement logic
}

export default FieldContainer;
