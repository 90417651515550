import {
    CheckoutAddressFormComponent as SourceCheckoutAddressFormComponent,
} from 'SourceComponent/CheckoutAddressForm/CheckoutAddressForm.component';
import MyAccountAddressForm from 'Component/MyAccountAddressForm/MyAccountAddressForm.component';
import { noopFn } from 'Util/Common';
import transformToNameValuePair from 'Util/Form/Transform';
import myAccountAddressForm from 'Component/MyAccountAddressForm/MyAccountAddressForm.form';


import './CheckoutAddressForm.override.style';

/** @namespace AlmusbahblendPwa/Component/CheckoutAddressForm/Component */
export class CheckoutAddressFormComponent extends SourceCheckoutAddressFormComponent {
    // TODO implement logic
    static defaultProps = {
        ...MyAccountAddressForm.defaultProps,
        onShippingEstimationFieldsChange: noopFn,
    };

    componentDidMount() {
        const {
            currentCity: city,
            currentRegionId: region_id,
            currentRegion: region,
            currentZipcode: postcode,
            currentTelephone: telephone,
            countryId,
            onShippingEstimationFieldsChange,
            selectedDeliveryAddress,
            defaultCountry,
        } = this.props;

        const addressSelected = {
            city: city,
            region_id: parseInt(region_id),
            region: region,
            postcode: postcode,
            country_id: countryId || defaultCountry,
            telephone: telephone,
        }

        selectedDeliveryAddress(addressSelected);

        onShippingEstimationFieldsChange({
            country_id: countryId || defaultCountry,
            // region_id,
            // region,
            // city,
            // postcode,
        });
    }

    fieldMap() {
        const {
            address,
            countries,
            addressLinesQty,
            regionDisplayAll,
            showVatNumber,
            defaultCountry,
            availableRegions,
            isStateRequired,
            countryId,
            currentRegion,
            currentCity,
            currentRegionId,
            currentZipcode,
            onCountryChange,
            onZipcodeChange,
            onCityChange,
            onRegionChange,
            onRegionIdChange,
        } = this.props;

        const fieldMap = myAccountAddressForm({
            address,
            countries,
            addressLinesQty,
            regionDisplayAll,
            showVatNumber,
            defaultCountry,
            availableRegions,
            isStateRequired,
            countryId,
            currentRegion,
            currentCity,
            currentRegionId,
            currentZipcode,
            ...address,
        }, {
            onCountryChange,
            onZipcodeChange,
            onCityChange,
            onRegionChange,
            onRegionIdChange,
        });

        const addressGroup = fieldMap.find((element) => {
            if (!('name' in element)) {
                return false;
            }

            const { name } = element;

            return name === 'addressGroup';
        });

        if (addressGroup) {
            addressGroup.events = {
                // Updates shipping methods on address blur
                onBlur: this.onAddressChange,
                onChange: this.onAddressChange,
            };
        }
        // Because of commentted default shipping/billing checkboxes - we should commnet below line
        // fieldMap.splice(0, 2);
        return fieldMap;
    }

    onAddressChange(event, data) {
        const { fields = {} } = data;
        const {
            country_id,
            region_id: regionId,
            region_string: region,
            city,
            postcode,
            telephone,
        } = transformToNameValuePair(fields);

        let addressFields =  {
            country_id,
            region_id: regionId,
            region,
            city,
            postcode,
            telephone
        }

        const { onShippingEstimationFieldsChange, selectedDeliveryAddress } = this.props;
        const request = {
            country_id,
            // region_id: regionId !== '' ? Number(regionId) : undefined,
            // region,
            // city,
            // postcode,
        };

        selectedDeliveryAddress(addressFields);

        // If request hasn't changed, then ignore.
        if (JSON.stringify(request) === JSON.stringify(this.lastRequest)) {
            return;
        }

        onShippingEstimationFieldsChange(request);

        // Caches last request
        this.lastRequest = request;
    }
}

export default CheckoutAddressFormComponent;
