// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Loader from 'Component/Loader';
import Otp from '../../util/Otp/OtpInput';
import './OtpInput.style';

function ShowError(props) {
    return <p> {props.message} </p>;
}

/** @namespace AlmusbahblendPwa/Component/OtpInput/Component */
export class OtpInputComponent extends PureComponent {

    state = {
        otp: '',
        numInputs: 4,
        separateAfter: 1,
        separator: '-',
        isDisabled: false,
        hasErrored: false,
        isSuccessed: false,
        isInputNum: false,
        isInputSecure: false,
        minLength: 0,
        maxLength: 40,
        placeholder: '',
        errorMessage: '',
        timer: 0,
        timerOn: false,
    };

    static propTypes = {
        // TODO: implement prop-types
    };

    componentDidMount() {
        const { showTimer } = this.props;
        if (showTimer) {
            this.handleTimer();
        }
        
    }

    handleOtpChange = (otp) => {
        console.log("handleOtpChange isNaN(otp) : ", isNaN(otp));
        this.setState({ otp });
        if (isNaN(otp)) {
            this.setState({
                hasErrored: true,
                errorMessage: 'Please enter numbers only.',
            });
        } else {
            this.setState({
                hasErrored: false,
                errorMessage: '',
            });
        }
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleNumInputsChange = (e) => {
        let numInputs = e.target.value;
        const { minLength, maxLength } = this.state;

        if (numInputs < minLength || numInputs > maxLength) {
            numInputs = 4;
            this.setState({
            hasErrored: true,
            errorMessage: `Please enter a value between ${minLength} and ${maxLength}`,
            });
            console.error(`Please enter a value between ${minLength} and ${maxLength}`);
        } else {
            this.setState({ hasErrored: false, errorMessage: '' });
        }

        this.setState({ [e.target.name]: parseInt(numInputs, 10) });
    };

    clearOtp = () => {
        this.setState({ otp: '' });
    };

    handleCheck = (e) => {
        const { name } = e.target;
        this.setState((prevState) => ({ [name]: !prevState[name] }));
    };

    handleSubmit = (e) => {
        const { verifyOtp, mobileNumber } = this.props;
        if (!this.state.hasErrored && !this.state.errorMessage) {
            try {
                const resp = verifyOtp(this.state.otp, mobileNumber);
                this.setState({ hasErrored: false, errorMessage: '' });
            } catch (error) {
                this.setState({
                    hasErrored: true,
                    errorMessage: 'Error Occurred, Please try again.',
                });
            }
        }
    };

    handleTimer() {
        const { success, otpValidTime, mobileNumber} = this.props;
        const { timer } = this.state;
        if(timer <= 0) {
            const futureDate = new Date();
            const s = futureDate.getSeconds();
            futureDate.setSeconds(s + otpValidTime);
            let timeStampTwo = futureDate.getTime();
            this.setState({ timerOn: true});
            const intervalId = setInterval(() => {
                let currentDate = new Date();
                let timeStampOne = currentDate.getTime();
            
                let remainingSeconds = parseInt((timeStampTwo-timeStampOne) / 1000);
                
                if(remainingSeconds < 0) {
                    this.setState({ timerOn: false});
                    clearInterval(intervalId);
                }
                this.setState({timer: remainingSeconds});
                
            }, 1000);
        }
    }

    handleResendOtp = (e) => {
        const { reSendOtpData, mobileNumber } = this.props;
        const resp = reSendOtpData(mobileNumber);
        this.handleTimer();
    }

    render() {
        const {
            otp,
            numInputs,
            separator,
            separateAfter,
            isDisabled,
            hasErrored,
            isSuccessed,
            isInputNum,
            isInputSecure,
            minLength,
            maxLength,
            placeholder,
            errorMessage,
            timer,
            timerOn,
        } = this.state;

        const {
            isLoading,
            success,
            otpValidTime,
            message,
            showTimer,
        } = this.props;

        let resendActive = false;
        if(timer <= 0) {
            resendActive = true;
        }

        let errorFound = hasErrored;
        let errorMsgTxt = errorMessage;
        let successMsgTxt = '';

        if (success == false) {
            errorFound = true;
            errorMsgTxt = message || errorMessage;
        } else if (hasErrored) {
            errorFound = true;
            errorMsgTxt = errorMessage || message;
        } else {
            errorFound = false;
            successMsgTxt = message;
        }

        return (
            <div block="OtpInput">
                <div block="ErrMsg">
                    {errorFound && errorMsgTxt && <ShowError message={errorMsgTxt} />}
                </div>
                <div block="SuccessMsg">
                    {!errorFound && successMsgTxt && <ShowError message={successMsgTxt} />}
                </div>
                <div block="View">
                    <div className="card">
                        <label className="title" htmlFor="otp-input-0">Enter OTP to validate</label>
                        <div className="margin-top--small">
                        <Otp
                            id="otp-input"
                            autoComplete="one-time-code"
                            inputStyle="inputStyle"
                            numInputs={numInputs}
                            isDisabled={isDisabled}
                            separateAfter={separateAfter}
                            hasErrored={hasErrored}
                            errorStyle="error"
                            isSuccessed={isSuccessed}
                            successStyle="success"
                            onChange={this.handleOtpChange}
                            separator={<span>{separator}</span>}
                            isInputNum={isInputNum}
                            isInputSecure={isInputSecure}
                            shouldAutoFocus
                            value={otp}
                            placeholder={placeholder}
                            onSubmit={() => console.log(otp)}
                        />
                        </div>
                        <div className='expire-otp-msg'>
                            {timer > 0 ? <p>OTP will expire in {timer} seconds.</p>: ''}
                        </div>
                        
                        <div className="btn-row">
                        {this.state.otp != '' ? (
                            <button className="btn margin-top--large" type="button" disabled={isDisabled} onClick={this.clearOtp}>
                            Clear
                            </button>
                        ) : (
                            <button className="btn margin-top--large" type="button" disabled onClick={this.clearOtp}>
                            Clear
                            </button>
                        )}
                        <button className="btn margin-top--large" type="button" disabled={otp.length < numInputs} onClick={this.handleSubmit}>
                            Verify OTP
                        </button>

                        <button className="btn margin-top--large" type="button" disabled={!resendActive} onClick={this.handleResendOtp}>
                            Resend OTP
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OtpInputComponent;
