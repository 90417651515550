import { connect } from 'react-redux';

import {
    CheckoutAddressFormContainer as SourceCheckoutAddressFormContainer,
} from 'SourceComponent/CheckoutAddressForm/CheckoutAddressForm.container';

import {
    mapDispatchToProps,
    mapStateToProps,
} from 'Component/MyAccountAddressForm/MyAccountAddressForm.container';

/** @namespace AlmusbahblendPwa/Component/CheckoutAddressForm/Container */
export class CheckoutAddressFormContainer extends SourceCheckoutAddressFormContainer {
    // TODO implement logic
    containerProps() {
        const { onShippingEstimationFieldsChange, selectedDeliveryAddress } = this.props;

        return {
            onShippingEstimationFieldsChange,
            selectedDeliveryAddress,
            ...super.containerProps(),
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddressFormContainer);
