import {
    CategoryPage,
    CmsPage,
    NoMatch,
    ProductPage,
    UrlRewritesComponent as SourceUrlRewritesComponent,
} from 'SourceRoute/UrlRewrites/UrlRewrites.component';

export {
    ProductPage,
    CategoryPage,
    CmsPage,
    NoMatch,
};

/** @namespace AlmusbahblendPwa/Route/UrlRewrites/Component */
export class UrlRewritesComponent extends SourceUrlRewritesComponent {
    // TODO implement logic
}

export default UrlRewritesComponent;
