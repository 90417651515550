import {
    CheckoutPaymentsComponent as SourceCheckoutPaymentsComponent,
} from 'SourceComponent/CheckoutPayments/CheckoutPayments.component';

import './CheckoutPayments.override.style';

/** @namespace AlmusbahblendPwa/Component/CheckoutPayments/Component */
export class CheckoutPaymentsComponent extends SourceCheckoutPaymentsComponent {
    // TODO implement logic
}

export default CheckoutPaymentsComponent;
