/**
 * @author Sanyasirao
 */

import { PureComponent } from 'react';

import Form from 'Component/Form';
import Loader from 'Component/Loader';
import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';

import './GiftMessage.style';

/** @namespace AlmusbahblendPwa/Component/GiftMessage/Component */
export class GiftMessageComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    static defaultProps = {
        giftFrom: '',
        giftTo: '',
        giftMessage: '',
    };

    state = {
        enteredFrom: '',
        enteredTo: '',
        enteredMessage: '',
        errorMessage: '',
        isFieldWithError: ''
    };

    __construct(props) {
        super.__construct?.(props);

        this.handleGiftMessageChange = this.handleGiftMessageChange.bind(this);
        this.handleGiftFromChange = this.handleGiftFromChange.bind(this);
        this.handleGiftToChange = this.handleGiftToChange.bind(this);
        this.handleApplyGiftMessage = this.handleApplyGiftMessage.bind(this);
        this.handleRemoveGiftMessage = this.handleRemoveGiftMessage.bind(this);
    }

    // componentDidUpdate(prevProps) {
    //     const { isIncorrectCoupon: prevIsIncorrectCoupon = false } = prevProps;
    //     const { isIncorrectCoupon = false, resetIsIncorrectCoupon } = this.props;

    //     if (isIncorrectCoupon && prevIsIncorrectCoupon !== isIncorrectCoupon) {
    //         this.toggleIsFieldWithError(isIncorrectCoupon);
    //         resetIsIncorrectCoupon();
    //     }
    // }

    // toggleIsMessageFieldWithError(value) {
    //     this.setState({
    //         isMessageFieldWithError: value,
    //     });
    // }

    // toggleIsFromFieldWithError(value) {
    //     this.setState({
    //         isFromFieldWithError: value,
    //     });
    // }

    // toggleIsToFieldWithError(value) {
    //     this.setState({
    //         isToFieldWithError: value,
    //     });
    // }

    handleGiftMessageChange(event, field) {
        const { value = '' } = field || {};
// console.log("enteredMessage", value);
        this.setState({
            enteredMessage: value,
            errorMessage: ''
        });
    }

    handleGiftFromChange(event, field) {
        const { value = '' } = field || {};
        // console.log("enteredFrom", value);
        this.setState({
            enteredFrom: value,
            errorMessage: ''
        });
    }

    handleGiftToChange(event, field) {
        const { value = '' } = field || {};
        this.setState({
            enteredTo: value,
            errorMessage: ''
        });
    }

    handleApplyGiftMessage() {
        const { handleApplyGiftMessageToCart } = this.props;
        const { enteredFrom, enteredTo, enteredMessage } = this.state;
        if (
            enteredFrom == '' ||
            enteredTo == '' ||
            enteredMessage == ''
        ) {
            this.setState({
                errorMessage: 'Please enter all values!'
            });
            return null;
        }
        const messageObj = {
            from: enteredFrom,
            to: enteredTo,
            message: enteredMessage,
        }
        this.setState({
            errorMessage: ''
        });
        handleApplyGiftMessageToCart(messageObj);
    }

    handleRemoveGiftMessage() {
        const { handleRemoveGiftMessageFromCart } = this.props;

        handleRemoveGiftMessageFromCart();

        // We need to reset input field. If we do it in applyGiftMessage
        this.setState({
            enteredFrom: '',
            enteredTo: '',
            enteredMessage: '',
        });
    }

    handleFormSubmit() {
        return null;
        const { giftMessage } = this.props;
        console.log("Gift message: ", giftMessage);

        if (giftMessage == null) {
            this.handleApplyGiftMessage();
        }

        
    }

    renderApplyGiftMessage() {
        const { enteredMessage, enteredFrom, enteredTo, errorMessage, isFieldWithError } = this.state;

        return (
            <>
                <div block="GiftMessage" elem="ErrorMessage">
                    <p> { errorMessage } </p>
                </div>
                <div block="GiftMessage" elem="InputText">
                    <Field
                      type={ FieldType.TEXT }
                      attr={ {
                          id: 'giftFrom',
                          name: 'giftFrom',
                          defaultValue: enteredFrom,
                          placeholder: __('From'),
                          'aria-label': __('From'),
                      } }
                      events={ {
                          onChange: this.handleGiftFromChange,
                      } }
                      validateOn={ ['onChange'] }
                    />
                    <Field
                      type={ FieldType.TEXT }
                      attr={ {
                          id: 'giftTo',
                          name: 'giftTo',
                          defaultValue: enteredTo,
                          placeholder: __('To'),
                          'aria-label': __('To'),
                      } }
                      events={ {
                          onChange: this.handleGiftToChange,
                      } }
                      validateOn={ ['onChange'] }
                    />
                </div>
                <div block="GiftMessage" elem="InputTextArea">
                    <Field
                      type={ FieldType.TEXTAREA }
                      attr={ {
                          id: 'giftMessage',
                          name: 'giftMessage',
                          defaultValue: enteredMessage,
                          placeholder: __('Your gift message'),
                          'aria-label': __('Your gift message'),
                      } }
                      events={ {
                          onChange: this.handleGiftMessageChange,
                      } }
                      validateOn={ ['onChange'] }
                    />

                    <button
                    block="GiftMessage"
                    elem="Button"
                    type={ FieldType.BUTTON }
                    mods={ { isHollow: true } }
                    disabled={ !enteredMessage }
                    onClick={ this.handleApplyGiftMessage }
                    >
                        { __('Add') }
                    </button>
                </div>
            </>
        );
    }

    renderRemoveGiftMessage() {
        const { isLoading, giftMessage, mix } = this.props;
        
        return (
            <div
            block="GiftMessage" elem="Added"
            mix={ mix }
            >
                <div block="GiftMessage" elem="MessageTo">
                    <p>
                        { __('To: ') }
                        <strong>{ giftMessage.to }</strong>
                    </p>
                </div>
                <div block="GiftMessage" elem="MessageFrom">
                    <p>
                        { __('From: ') }
                        <strong>{ giftMessage.from }</strong>
                    </p>
                </div>
                <div block="GiftMessage" elem="MessageText">
                    <p>
                        { __('Gift Message: ') }
                        <strong>{ giftMessage.message }</strong>
                    </p>
                </div>
                <button
                  block="GiftMessage"
                  elem="Button"
                  type="button"
                  mix={ { block: 'Button' } }
                  onClick={ this.handleRemoveGiftMessage }
                >
                    { __('Remove GiftMessage') }
                </button>
            </div>
        );
    }

    renderTitle() {
        const { title = '' } = this.props;

        if (!title) {
            return null;
        }

        return (
            <h3 block="GiftMessage" elem="Title">
                { title }
            </h3>
        );
    }

    render() {
        const { isLoading, giftMessage, mix } = this.props;

        // if (giftMessage != null) {
        //     this.renderRemoveGiftMessage();
        // } else {

            return (
                <div
                block="GiftMessage"
                mix={ mix }
                >
                    <Form
                    onSubmit={ this.handleFormSubmit }
                    returnAsObject
                    >
                        <Loader isLoading={ isLoading } />
                        {/* { this.renderTitle() } */}
                        { (giftMessage
                            ? this.renderRemoveGiftMessage()
                            : this.renderApplyGiftMessage()
                        ) }
                    </Form>
                </div>
            );
        // }
    }
}

export default GiftMessageComponent;
