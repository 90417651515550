import { connect } from 'react-redux';

import {
    CheckoutTermsAndConditionsPopupContainer as SourceCheckoutTermsAndConditionsPopupContainer,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/CheckoutTermsAndConditionsPopup/CheckoutTermsAndConditionsPopup.container';

export {
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Component/CheckoutTermsAndConditionsPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    // ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace AlmusbahblendPwa/Component/CheckoutTermsAndConditionsPopup/Container */
export class CheckoutTermsAndConditionsPopupContainer extends SourceCheckoutTermsAndConditionsPopupContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutTermsAndConditionsPopupContainer);
