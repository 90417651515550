import {
    CheckoutAddressBookComponent as SourceCheckoutAddressBookComponent,
} from 'SourceComponent/CheckoutAddressBook/CheckoutAddressBook.component';
import CheckoutAddressForm from 'Component/CheckoutAddressForm';

/** @namespace AlmusbahblendPwa/Component/CheckoutAddressBook/Component */
export class CheckoutAddressBookComponent extends SourceCheckoutAddressBookComponent {
    // TODO implement logic
    renderCustomAddress() {
        const { onShippingEstimationFieldsChange, selectedDeliveryAddress } = this.props;

        return (
            <CheckoutAddressForm
                selectedDeliveryAddress={ selectedDeliveryAddress }
                onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
            />
        );
    }
}

export default CheckoutAddressBookComponent;
