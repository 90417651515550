// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Loader from 'Component/Loader';
import Html from 'Component/Html';
import ContentWrapper from 'Component/ContentWrapper';
import './TrackShipment.style';

/** @namespace AlmusbahblendPwa/Route/TrackShipment/Component */
export class TrackShipmentComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderTrackDetails() {

        return (
            <main block="TrackShipment">
                <ContentWrapper
                   wrapperMix={ { block: 'TrackShipment', elem: 'Wrapper' } }
                   label={ __('Tracking Information') }
                 >
                     <div block="TrackShipment" elem="FullData">
                        <div>
                            <h2>{ __('Tracking Information')}</h2>
                        </div>
                        { this.renderTrackingMainData() }
                        { this.renderTrackingProgressData() }
                        { this.renderTrackingDeliveryInfo() }
                     </div>
                 </ContentWrapper>
            </main>
       );
    }

    renderTrackingMainData() {
        const {
            trackingData: { shipment_id = '', tracking_data = {} } = {},
        } = this.props;

        const { 
            carrier_title = '', 
            track_number = '',
            summery = '',
            error_message = '',
        } = tracking_data;

        return (
            <>
                <div block="TrackShipment" elem="ShipmentId">
                    { __('Shipment Number: %s', shipment_id) }
                </div>
                <div block="TrackShipment" elem="CarrierTitle">
                    { __('Carrier: %s', carrier_title) }
                </div>
                <div block="TrackShipment" elem="TrackingNumber">
                    { __('Tracking Number: %s', track_number) }
                </div>
                <div block="TrackShipment" elem="ErrorMessage">
                    { error_message && __('Error: %s', error_message) }
                </div>

                <div block="TrackShipment" elem="Info">
                    { this.renderTrackingInfo() }
                </div>
                
            </>
        );
    }

    renderTrackingInfo() {
        const {
            trackingData: {tracking_data = {} } = {},
        } = this.props;

        const { 
            summery = '',
        } = tracking_data;

        return (
            <div block="TrackShipment" elem="InfoData">
                <Html content={ summery } />
            </div>
        );
    }

    renderTrackingProgressData() {

        return null;
        const {
            trackingData: { tracking_data = {} } = {},
        } = this.props;

        const { 
            progress_data: progressData = [],
        } = tracking_data;

        if(progressData.length == 0) {
            return null;
        }

        return (
            <table
            block="TrackShipmentTable"
            elem="ProgressData"
            >
                <thead>
                    <th block="TrackShipmentTable" elem="Location">
                        { __('Location') }
                    </th>
                    <th block="TrackShipmentTable" elem="Date">
                        { __('Date') }
                    </th>
                    <th block="TrackShipmentTable" elem="Local Time">
                        { __('Local Time') }
                    </th>
                    <th block="TrackShipmentTable" elem="Description">
                        { __('Description') }
                    </th>
                </thead>
                { this.renderProgressDataValues() }
            </table>
        );
    }

    renderProgressDataValues() {
        return null;
    }


    renderTrackingDeliveryInfo() {
        return null;
        const {
            trackingData: { tracking_data = {} } = {},
        } = this.props;

        const { 
            delivery_info: deliveryInfo = [],
        } = tracking_data;

        if(deliveryInfo.length == 0) {
            return null;
        }

        return (
            <table
            block="TrackShipmentTable"
            elem="DeliveryInfo"
            >
                { this.renderProgressDataValues() }
            </table>
        );

    }

    renderFullPageLoader() {
        return (
             <main block="TrackShipment" elem="FullPageLoader">
                 <Loader isLoading />
             </main>
        );
    }

    renderContent() {
        const {
            isLoading
        } = this.props;

        if (isLoading) {
            return this.renderFullPageLoader();
        }

        return this.renderTrackDetails();
    }

    render() {
        return (
            <div block="TrackShipment">
                { this.renderContent() }
            </div>
            
        )
    }
}

export default TrackShipmentComponent;
