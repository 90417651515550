// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import TrackShipment from './TrackShipment.component';
import Loader from 'Component/Loader';

import { prepareQuery } from 'Util/Query';
import { executePost } from 'Util/Request/Request';
import MyAccountQuery from 'Query/MyAccount.query';

/** @namespace AlmusbahblendPwa/Route/TrackShipment/Container */
export class TrackShipmentContainer extends PureComponent {

    state = {
        success: false,
        result: false,
        isLoading: true,
        trackingData: {},
    };

    componentDidMount() {

        const urlParams = new URLSearchParams(window.location.search);
        // console.log("urlParams --> ", urlParams);
        const hashCode = urlParams.get('hash');

        // console.log("Tracking hashCode: ", hashCode);
        this.requestTrackingData(hashCode);
        
    }

    async requestTrackingData(hashCode) {
        const query = MyAccountQuery.getTrackingData(hashCode);
        executePost(prepareQuery([query])).then(
            /** @namespace AlmusbahblendPwa/Route/TrackShipment/Container/executePost/then */
            ( data ) => {
                console.log("=== Order TrackingData: ", data.getShippingTracking);
                this.setState({ 
                    isLoading: false,
                    result: true,
                    trackingData: data.getShippingTracking,
                });
            }
        ).catch(
            /** @namespace AlmusbahblendPwa/Route/TrackShipment/Container/executePost/then/catch */
            () => {
                this.setState({ isLoading: false })
            }
        );
    }

    containerProps() {
        
        const { success, isLoading, result, trackingData  } = this.state;

        return {
            success,
            result,
            isLoading,
            trackingData
        };
    }

    render() {
        return (
             <TrackShipment
               { ...this.containerProps() }
             />
        );
    }
}

export default TrackShipmentContainer;
