import { connect } from 'react-redux';

import history from 'Util/History';
import {
    UrlRewritePageType,
} from 'SourceRoute/UrlRewrites/UrlRewrites.config';

import {
    mapDispatchToProps,
    mapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer,
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    // TODO implement logic

    getTypeSpecificProps() {
        const {
            actionName: {
                id: actionNameId,
                display_mode: preloadDisplayMode,
            } = {},
            isPrefetchValueUsed,
        } = window;
        const {
            urlRewrite: {
                id = actionNameId || undefined,
                sku,
                display_mode,
                sort_by,
            },
        } = this.props;
        // console.log("UrlRewrite getTypeSpecificProps window: ", window);
        // console.log("UrlRewrite getTypeSpecificProps this.props: ", this.props);
        const isLoading = this.getIsLoading();
        switch (this.getType()) {
        case UrlRewritePageType.PRODUCT:
            /**
                 * In case we are not yet sure what product ID it is:
                 * - check if there is a hint in browser history
                 * - fallback to none
                 */
            if (isLoading) {
                // TODO: history.state.state looks like undefined all the time.
                const product = history?.location?.state?.product;

                if (product) {
                    const { sku: historySKU, id } = product;

                    return { productSKU: historySKU, id };
                }

                return {};
            }

            return { productSKU: sku, id };
        case UrlRewritePageType.CMS_PAGE:
            if (isLoading) {
                return { isOnlyPlaceholder: true };
            }

            return { pageIds: id };
        case UrlRewritePageType.CATEGORY:
            /**
                 * In case we are not yet sure what category ID it is:
                 * - check if there is a hint in browser history
                 * - fallback to none
                 */
            if (isLoading) {
                // TODO: history.state.state looks like undefined all the time.
                if (history) {
                    const {
                        location: {
                            state: {
                                category,
                                displayMode,
                            } = {},
                        } = {},
                    } = history;

                    if (category && category !== true) {

                        return {
                            categoryIds: category,
                            displayMode: isPrefetchValueUsed ? preloadDisplayMode : displayMode,
                            sort_by,
                        };
                    }
                }

                return {};
            }

            return {
                categoryIds: isPrefetchValueUsed && this.initialUrl === location.pathname ? window.actionName.id : id,
                displayMode: isPrefetchValueUsed ? preloadDisplayMode : display_mode,
                sort_by,
            };
        case UrlRewritePageType.NOTFOUND:
        default:
            return {};
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
