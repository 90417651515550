/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/**
 * Slider Query
 * @class Slider
 * @namespace Query/HyperpayStatusQuery */
export class HyperpayStatusQuery {
    // createPaymentIntent(options) {
    //     return new Field('createPaymentIntent')
    //         .addArgument('input', 'CreateIntentInput!', options)
    //         .addField('intent_client_secret');
    // }

    getHyperpayStatus(id, paymentMethod) {
        return new Field('getpaymentstatus')
        .addArgument('payment_id', 'String', id)
        .addArgument('payment_method', 'String', paymentMethod)
        .addFieldList(this._getOrderFields());
    }

    _getOrderFields() {
        return [
            'paymentStatus',
            'success',
            'orderId',
            'orderNumber',
            'email',
            'firstname',
            'lastname'
        ];
    }
}

export default new HyperpayStatusQuery();
