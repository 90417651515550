/**
 * @author - Sanyasi Rao
 */

import { Field } from 'Util/Query';

/**
 * Slider Query
 * @class Slider
 * @namespace Query/OtpQuery */
export class OtpQuery {
    sendOtp(mobileNumber) {
        console.log("received mobileNumber: ", mobileNumber);
        return new Field('sendotp')
        .addArgument('mobile_number', 'String', mobileNumber)
        .addFieldList(this._getResultFields());
    }

    verifyOtp(otp, mobileNumber) {
        console.log("received otp: ", otp);
        return new Field('verifyotp')
        .addArgument('otp', 'String', otp)
        .addArgument('mobile_number', 'String', mobileNumber)
        .addFieldList(this._getVerifyResultFields());
    }

    reSendOtp(mobileNumber) {
        console.log("received mobileNumber: ", mobileNumber);
        return new Field('resendotp')
        .addArgument('mobile_number', 'String', mobileNumber)
        .addFieldList(this._getResultFields());
    }

    _getResultFields() {
        return [
            'success',
            'valid_time',
            'message',
            'otp',
        ];
    }

    _getVerifyResultFields() {
        return [
            'success',
            'message',
        ];
    }
}

export default new OtpQuery();
