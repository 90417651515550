const config = {
	extCodes: [
		{ code: 'SA', text: '+966', length: 9, start: ['5'], emoji: "🇸🇦", flag: '/static/content/flag-saudi.png', regEx: '^(5)[0-9]{8}$', repExp: '^(00966|966|\\+966)', pHolder: '5X XXX XXXX' },
		{ code: 'AE', text: '+971', length: 9, start: ['5'], emoji: "🇦🇪", flag: '/static/content/flag-uae.png', regEx: '^(5)[0-9]{8}$', repExp: '^(00971|971|\\+971)', pHolder: '5X XXX XXXX' },
		{ code: 'BH', text: '+973', length: 8, start: ['3'], emoji: "🇧🇭", flag: '/static/content/flag-baharain.png', regEx: '^(3)[0-9]{7}$', repExp: '^(00973|973|\\+973)', pHolder: '3X XXX XXX' },
		{ code: 'OM', text: '+968', length: 8, start: ['7', '9'], emoji: "🇴🇲", flag: '/static/content/flag-oman.png', regEx: '^(7|9)[0-9]{7}$', repExp: '^(00968|968|\\+968)', pHolder: '7|9X XXX XXX' },
		{ code: 'KW', text: '+965', length: 8, start: ['5', '6', '9'], emoji: "🇰🇼", flag: '/static/content/flag-kuwait.png', regEx: '^(5|6|9)[0-9]{7}$', repExp: '^(00965|965|\\+965)', pHolder: '5|6|9X XXX XXX' },
		{ code: 'QA', text: '+974', length: 8, start: ['3', '5', '6','7'], emoji: "🇶🇦", flag: '/static/content/flag-qatar.png', regEx: '^(3|5|6|7|)[0-9]{7}$', repExp: '^(00974|974|\\+974)', pHolder: '3|5|6|7X XXX XXX' },
		// { code: 'IN', text: '+91', length: 10, start: ['6', '7', '8', '9'], emoji: "", flag: '/static/content/flag-india.png', regEx: '(6|7|8|9)[0-9]{9}$', repExp: '^(0091|91|\\+91)', pHolder: '9XXXX XXXXX'}
	],
	lengthCheck: (val = '', lens = [4, 50]) => {
		if (val.length < lens[0]) {
			return lens[0];
		}
		if (val.length > lens[1]) {
			return lens[1];
		} else {
			return null;
		}
	},
	messages: {
		country: {
			'en-sa': 'Please enter a valid Country Name',
			'ar-sa': 'لا يسمح باستخدام هذه الرموز',
		},
		city: {
			'4': {
				'en-sa': 'Oops! Expected a minimum of 4 characters',
				'ar-sa': 'عذراًُ، يجب أن لا يتعدى عدد الحروف عن 4‎',
			},
			'100': {
				'en-sa': 'Oops! Expected a maximum of 100 characters',
				'ar-sa': 'عذراًُ، يجب أن لا يتعدى عدد الحروف عن 100',
			},
			'en-sa': 'Please enter a valid City Name',
			'ar-sa': 'لا يسمح باستخدام هذه الرموز',
		},
		district: {
			'4': {
				'en-sa': 'Oops! Expected a minimum of 4 characters',
				'ar-sa': 'عذراًُ، يجب أن لا يتعدى عدد الحروف عن 4‎',
			},
			'100': {
				'en-sa': 'Oops! Expected a minimum of 100 characters',
				'ar-sa': 'عذراًُ، يجب أن لا يتعدى عدد الحروف عن 100'
			},
			'en-sa': 'Please enter a valid District Name',
			'ar-sa': 'فضلاً ادخل اسم الحي الصحيح',
		},
		street: {
			'4': {
				'en-sa': 'Oops! Expected a minimum of 4 characters',
				'ar-sa': 'عذراًُ، يجب أن لا يتعدى عدد الحروف عن  4'
			},
			'100': {
				'en-sa': 'Oops! Expected a maximum of 100 characters',
				'ar-sa': 'عذراًُ، يجب أن لا يتعدى عدد الحروف عن 100'
			},
			'en-sa': 'Please enter a valid Street Address',
			'ar-sa': 'الرجاء إدخال العنوان',
		},
		zipcode: {
			'2': {
				'en-sa': 'Oops! Expected a minimum of 2 characters',
				'ar-sa': 'عذراًُ، يجب أن لا يتعدى عدد الحروف عن 3‎'
			},
			'9': {
				'en-sa': 'Oops! Expected a maximum of 9 characters',
				'ar-sa': 'عذراًُ، يجب أن لا يتعدى عدد الحروف عن 9'
			},
			'ar-sa': 'الرجاء إدخال الإسم الأول',
			'en-sa': 'Please enter a valid zipcode',
		},
		firstname: {
			'3': {
				'en-sa': 'Oops! Expected a minimum of 3 characters , Please enter a valid Full Name',
				'ar-sa': 'عذراًُ، يجب أن لا يتعدى عدد الحروف عن 3‎, الرجاء إدخال الإسم الأول'
			},
			'40': {
				'en-sa': 'Oops! Expected a maximum of 40 characters',
				'ar-sa': 'عذراًُ، يجب أن لا يتعدى عدد الحروف عن 40'
			},
			'en-sa': 'Please enter a valid Full Name',
			'ar-sa': 'الرجاء إدخال الإسم الأول',
		},
		lastname: {
			'1': {
				'en-sa': 'Oops! Expected a minimum of 1 characters ,Please enter a valid Last Name ',
				'ar-sa': 'عذراًُ، يجب أن لا يتعدى عدد الحروف عن 1‎ , الرجاء إدخال الإسم الأخير'
			},
			'40': {
				'en-sa': 'Oops! Expected a maximum of 40 characters',
				'ar-sa': 'عذراًُ، يجب أن لا يتعدى عدد الحروف عن 40'
			},
			'en-sa': 'Please enter a valid Last Name',
			'ar-sa': 'الرجاء إدخال الإسم الأخير',
		},
		telephone: {
			'en-sa': 'The Mobile number you have entered is invalid',
			'ar-sa': 'يرجى إدخال رقم جوال صحيح!',
		},
	},
};

export default config;
