/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import { prepareQuery } from 'Util/Query';
import { executePost } from 'Util/Request/Request';
// import { RootState } from 'Util/Store/Store.type';
import OtpQuery from '../../query/Otp.query';
import OtpInputComponent from './OtpInput.component';
import Loader from 'Component/Loader';

/** @namespace AlmusbahblendPwa/Component/ProceedPayment/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    // TODO extend mapStateToProps
    // totals: state.CartReducer.cartTotals,
});

/** @namespace ProceedPaymentContainer/Component/ProceedPayment/Container/ProceedPaymentContainer */
export class OtpInputContainer extends PureComponent {

    state = {
        isLoading: true,
        success: false,
        otpValidTime: 0,
        message: '',
        response: false,
        otpValidated: false,
        showTimer: false,
    };

    componentDidMount() {
        const { mobileNumber = '' } = this.props;
        if (mobileNumber != '') {
            this.sendOtpData(mobileNumber);
        } else {
            this.setState({ message: 'Mobile Number required to send Otp.'});
        }
        
    }

    containerProps = () => {
        const { isLoading } = this.state;
        const { success } = this.state;
        const { otpValidTime } = this.state;
        const { message } = this.state;
        const { showTimer } = this.state;
        const { mobileNumber } = this.props;
    
        return {
            isLoading,
            success,
            otpValidTime,
            message,
            showTimer,
            mobileNumber,
        };
    };

    sendOtpData(mobileNumber) {

        const query = OtpQuery.sendOtp(mobileNumber);
        executePost(prepareQuery([query])).then(
            /** @namespace OtpInput/Component/OtpInput/Container/executeGet/then */
            ( data ) => {
                console.log("===  OTP Number is: ", data.sendotp.otp);
                this.setState({ 
                    isLoading: false, 
                    success: data.sendotp.success,
                    otpValidTime: data.sendotp.valid_time,
                    message: data.sendotp.message,
                    response: true,
                    showTimer: true,
                });
                // console.log("=== check step 5: ");
            }
        ).catch(
            /** @namespace OtpInput/Component/Hyperpay/Container/executeGet/then/catch */
            () => {
                this.setState({ isLoading: false })
            }
        );
    }

    reSendOtpData(mobileNumber) {
        const query = OtpQuery.reSendOtp(mobileNumber);
        executePost(prepareQuery([query])).then(
            /** @namespace OtpInput/Component/OtpInput/Container/executeGet/then */
            ( data ) => {
                console.log("=== Resend OTP Number is: ", data.resendotp.otp);
                this.setState({ 
                    isLoading: false, 
                    success: data.resendotp.success,
                    otpValidTime: data.resendotp.valid_time,
                    message: data.resendotp.message,
                    response: true,
                    showTimer: true,
                });
                // console.log("=== check step 5: ");
            }
        ).catch(
            /** @namespace OtpInput/Component/Hyperpay/Container/executeGet/then/catch */
            () => {
                this.setState({ isLoading: false })
            }
        );
    }

    verifyOtp(otp, mobileNumber) {
        const { validateMobileOtp } = this.props;
        const query = OtpQuery.verifyOtp(otp, mobileNumber);
        executePost(prepareQuery([query])).then(
            /** @namespace OtpInput/Component/OtpInput/Container/executeGet/then */
            ( data ) => {
                console.log("=== Verify OTP Data : ", data.verifyotp);
                this.setState({ 
                    isLoading: false, 
                    success: data.verifyotp.success,
                    message: data.verifyotp.message,
                    response: true,
                    otpValidated: data.verifyotp.success,
                    showTimer: false,
                });

                validateMobileOtp(data.verifyotp.success);

                // console.log("=== check step 5: ");
            }
        ).catch(
            /** @namespace OtpInput/Component/Hyperpay/Container/executeGet/then/catch */
            () => {
                this.setState({ isLoading: false })
            }
        );
    }

    containerFunctions = {
        verifyOtp: this.verifyOtp.bind(this),
        reSendOtpData: this.reSendOtpData.bind(this),
    };


    render() {
        const { response, isLoading, otpValidated, success, message } = this.state;

       if(otpValidated) {
            return (
                <div block="OtpInput">
                    <div block="SuccessMsg">
                        <p>Given phone number verified successfully.</p>
                    </div>
                </div>
            );
        } else if(response) {
            if(!success) {
                return (
                    <div block="OtpInput">
                        <div block="ErrMsg">
                            <p>{message}</p>
                        </div>
                    </div>
                );
            } else {
                return (
                    <OtpInputComponent 
                        { ...this.containerFunctions }
                        { ...this.containerProps() } 
                    />
                );
            }
        } else {
            return (<Loader isLoading={isLoading} />);
        }

        
    }
}

export default OtpInputContainer;
