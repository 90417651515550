/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import { prepareQuery } from 'Util/Query';
import { executePost } from 'Util/Request/Request';
// import { RootState } from 'Util/Store/Store.type';
import HyperpayQuery from '../../query/Hyperpay.query';
import ProceedPaymentComponent from './ProceedPayment.component';

/** @namespace AlmusbahblendPwa/Component/ProceedPayment/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    // TODO extend mapStateToProps
    totals: state.CartReducer.cartTotals,
});

/** @namespace ProceedPaymentContainer/Component/ProceedPayment/Container/ProceedPaymentContainer */
export class ProceedPaymentContainer extends PureComponent {

    state = {
        isLoading: true,
        orderId: '00000',
        checkoutId: '',
        baseUrl: '',
    };

    componentDidMount() {
        const { selectedPaymentMethod, orderID } = this.props;
        this.requestPaymentFormData(orderID);
    }


    containerProps = () => {
        const { isLoading } = this.state;
        const { orderId } = this.state;
        const { checkoutId } = this.state;
        const { baseUrl } = this.state;
        const locale = 'en_US';
        const rtl = false;
        const customClass='saved-card-form';
        const newCard=false;
        const paymentBrand ='VISA MASTER MADA';
        // const totalss = RootState.CartReducer.cartTotals;

        const {
            setLoading,
            setDetailsStep,
            shippingAddress,
            paymentMethods = [],
            savePaymentInformation,
            selectedShippingMethod,
            onChangeEmailRequired,
            totals,
            selectedPaymentMethod,
            orderID,
        } = this.props;

        
        return {
            isLoading,
            orderId,
            checkoutId,
            baseUrl,
            locale,
            rtl,
            paymentBrand,
            customClass,
            newCard,
            setLoading,
            setDetailsStep,
            shippingAddress,
            paymentMethods,
            savePaymentInformation,
            selectedShippingMethod,
            onChangeEmailRequired,
            totals,
            selectedPaymentMethod,
            orderID,
        };
    };

    requestPaymentFormData(orderID) {
        const query = HyperpayQuery.getHyperpayData(orderID);
        executePost(prepareQuery([query])).then(
            /** @namespace HyperpayPayments/Component/Hyperpay/Container/executeGet/then */
            ( formUrl ) => {
                console.log("=== requestPaymentFormData url: ", formUrl.getpaymenturl.formUrl);
                this.setState({ 
                    isLoading: false, 
                    checkoutId: formUrl.getpaymenturl.formUrl,
                    baseUrl: formUrl.getpaymenturl.baseurl,
                });
                // console.log("=== check step 5: ");
            }
        ).catch(
            /** @namespace HyperpayPayments/Component/Hyperpay/Container/executeGet/then/catch */
            () => {
                this.setState({ isLoading: false })
            }
        );
    }

    render() {
        const { isLoading } = this.state;

        return (
            <ProceedPaymentComponent { ...this.containerProps() } />
        );
    }
}

export default ProceedPaymentContainer;
