// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './TamaraStatus.style';
import Loader from 'Component/Loader';
import { prepareQuery } from 'Util/Query';
import { executePost } from 'Util/Request/Request';
// import { RootState } from 'Util/Store/Store.type';
import TamaraQuery from '../../query/Tamara.query';
import TamaraDetails from 'Component/TamaraDetails';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace AlmusbahblendPwa/Route/TamaraStatus/Component */
export class TamaraStatusComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    state = {
        success: false,
        result: false,
        isLoading: true,
        orderID: '',
        orderNumber: '',
        email: '',
        firstname: '',
        lastname: '',
        message: '',
    };

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        console.log("urlParams --> ", urlParams);
        const tamaraPaymentStatus = urlParams.get('paymentStatus');
        const tamaraOrderId = urlParams.get('orderId');
        // const paymentResourcePath = urlParams.get('resourcePath');
        
        // console.log(" In Details this.props is: ", this.props);
        const {
            match: { params = {} },
        } = this.props;
        console.log(" In Details params is: ", params);
        const { order_id, status } = params;
        // console.log(" In Details method is: ", method);


        const paymentData = {
            orderId: order_id,
            status: status,
            paymentStatus: tamaraPaymentStatus,
            tamaraOrderId: tamaraOrderId,
        };

        this.saveTamaraPaymentStatus(paymentData);
    }

    saveTamaraPaymentStatus(paymentData) {
        const query = TamaraQuery.updateTamaraPaymentStatus(paymentData);
        executePost(prepareQuery([query])).then(
            /** @namespace AlmusbahblendPwa/Route/TamaraStatus/Component/executeGet/then */
            ( data ) => {
                console.log("=== tamarapaymentresponse: ", data.tamarapaymentresponse);
                this.setState({ 
                    isLoading: false,
                    result: true,
                    success: data.tamarapaymentresponse.status,
                    orderID: data.tamarapaymentresponse.orderId,
                    orderNumber: data.tamarapaymentresponse.orderNumber,
                    email: data.tamarapaymentresponse.email,
                    firstname: data.tamarapaymentresponse.firstname,
                    lastname: data.tamarapaymentresponse.lastname,
                    message: data.tamarapaymentresponse.message
                });
                // console.log("=== check step 5: ");
            }
        ).catch(
            /** @namespace AlmusbahblendPwa/Route/TamaraStatus/Component/executeGet/then/catch */
            () => {
                this.setState({ isLoading: false })
            }
        );
    }

    containerProps() {
       const { 
        isLoading,
        success,
        result,
        orderID,
        orderNumber,
        email,
        firstname,
        lastname,
        message,
       } = this.state;

       return {
            isLoading,
            success,
            result,
            orderID,
            orderNumber,
            email,
            firstname,
            lastname,
            message
       }
    }
     
    // render() {
    //     const {
    //         isLoading,
    //         result,
    //     } = this.state;
    //     console.log("Calling render function TamaraStatus---", result);
    //     if(result) {
    //         return (
    //             <TamaraDetails {...this.containerProps()} />
    //         );
    //     } else {
        
    //         return (
    //             <div block="TamaraStatus">
    //                 <Loader isLoading={ isLoading } />
    //             </div>
    //         );
    //     }
    // }
    
    redirectToStatus() {
        const {
            orderID,
            success,
        } = this.state;
        if(success) {
            history.push(appendWithStoreCode(`/order-success/${orderID}/success`));
        } else {
            history.push(appendWithStoreCode(`/order-failure/${orderID}/failed`));
        }
        
    }

    renderFullPageLoader() {
        return (
             <main block="Checkout" elem="FullPageLoader">
                 <Loader isLoading />
             </main>
        );
    }

    renderContent() {
        const {
            isLoading
        } = this.state;

        if (isLoading) {
            return this.renderFullPageLoader();
        }

        return this.redirectToStatus();
    }

    render() {
        return (
            <div block="TamaraStatus">
                { this.renderContent() }
            </div>
            
        )
    }
}

export default TamaraStatusComponent;
