import {
    LoaderComponent as SourceLoaderComponent,
} from 'SourceComponent/Loader/Loader.component';
import loadingIcon from 'Style/loader.gif';

/** @namespace AlmusbahblendPwa/Component/Loader/Component */
export class LoaderComponent extends SourceLoaderComponent {
    // TODO implement logic
    renderMain() {
        return (
        <img src={ loadingIcon } alt="LogoPlaceholder" />
        );
    }
}

export default LoaderComponent;
