import { PureComponent, createRef } from 'react';

import {
    MyAccountSignInComponent as SourceMyAccountSignInComponent,
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.component';

import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import { ValidationInputType } from 'Util/Validator/Config';

import ReCAPTCHA from "react-google-recaptcha";
// import GoogleRecaptcha from 'Component/GoogleRecaptcha';
import { setCaptchaToken, getCaptchaToken } from 'Util/Auth/CaptchaToken';
import { CONFIG_VAR } from './../../config/env';

import './MyAccountSignIn.override.style';

/** @namespace AlmusbahblendPwa/Component/MyAccountSignIn/Component */
export class MyAccountSignInComponent extends SourceMyAccountSignInComponent {
    // TODO implement logic

    _reCaptchaRef = createRef();

    __construct(props) {
        super.__construct?.(props);

        this.generateGoogleRecaptchaToken = this.generateGoogleRecaptchaToken.bind(this);
    }

    // state = {
    //     reCaptchaTokenGenerated: true,
    // }

    renderSignInForm() {
        // const { reCaptchaTokenGenerated } = this.state;
        
        const {
            onSignInSuccess,
            onFormError,
            handleForgotPassword,
            emailValue,
            isCheckout,
            handleEmailInput,
            isLoading,
        } = this.props;

        return (
            <Form
              key="sign-in"
              onSubmit={ this.generateGoogleRecaptchaToken }
              onError={ onFormError }
            >
                <Field
                  label={ __('Email') }
                  type={ FieldType.EMAIL }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      placeholder: __('Your email address'),
                      defaultValue: emailValue,
                      autoComplete: isCheckout ? 'off' : 'email',
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: ValidationInputType.EMAIL,
                  } }
                  events={ { onChange: handleEmailInput } }
                  addRequiredTag
                />
                <Field
                  label={ __('Password') }
                  type={ FieldType.PASSWORD }
                  attr={ {
                      id: 'password',
                      name: 'password',
                      placeholder: __('Enter your password'),
                      autoComplete: 'current-password',
                  } }
                  validateOn={ isCheckout ? ['onSubmit'] : ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: ValidationInputType.PASSWORD,
                  } }
                  addRequiredTag
                />
                <button
                  type="button"
                  block="Button"
                  mods={ { likeLink: true } }
                  mix={ { block: 'MyAccountOverlay', elem: 'ForgotPassword' } }
                  onClick={ handleForgotPassword }
                >
                    { __('Forgot password?') }
                </button>
                { (CONFIG_VAR.ENABLE_GRECAPTCHA || false) && <ReCAPTCHA
                    style={{ display: "inline-block" }}
                    theme="dark"
                    size="invisible"
                    ref={this._reCaptchaRef}
                    onChange={this.handleChange}
                    sitekey={CONFIG_VAR.GRECAPTCHA_SITEKEY || ''}
                /> }
                <div block="MyAccountOverlay" elem="SignInButton">
                    <button block="Button">{ __('Sign in') }</button>
                </div>
                <Loader isLoading={ isLoading } />
            </Form>
        );
    }

    handleChange = value => {
        setCaptchaToken(value);
    };

    generateGoogleRecaptchaToken(form, fields) {
        const {
            onSignInSuccess,
        } = this.props;

        if ((CONFIG_VAR.ENABLE_GRECAPTCHA || false) && (getCaptchaToken() == (null || undefined))) {
            this._reCaptchaRef.current.executeAsync().then(async (value) => {
                onSignInSuccess(form, fields);
                await this._reCaptchaRef.current.reset();
            });
        } else {
            onSignInSuccess(form, fields);
        }

        
    }

    render() {
        return (
            <>
                { this.renderSignInForm() }
                { this.renderAdditionalField() }
            </>
        );
    }

}

export default MyAccountSignInComponent;
