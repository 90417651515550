/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { lazy, PureComponent, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';
import './Status.style';
// import CheckoutSuccess from 'Component/CheckoutSuccess';

import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import {
    CHECKOUT_URL_REGEX,
    CheckoutSteps,
    CheckoutStepUrl,
} from 'Route/Checkout/Checkout.config';

export const CheckoutSuccess = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-success" */
    'Component/CheckoutSuccess'
));

/** @namespace Route/Checkout/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    selectedStore: state.StoreInPickUpReducer.store,
    customer: state.MyAccountReducer.customer,
    isEmailAvailable: state.CheckoutReducer.isEmailAvailable,
    isSignedIn: state.MyAccountReducer.isSignedIn,
});


/** @namespace HyperpayPayments/Component/Status/Component/StatusComponent */
export class StatusComponent extends PureComponent {
    static propTypes = {

    };

    renderLoader() {
        const { isLoading } = this.props;

        return <Loader isLoading={ isLoading } />;
    }

    renderContent() {
        const {
            result,
            title,
            orderID,
            isEmailAvailable,
            email,
            firstname,
            lastname,
            CheckoutStep,
        } = this.props;

        return (
                <div block="Checkout" elem="Step">
                    <div block="Checkout" elem="Header">
                        <div block="Checkout" elem="Title">{ title }</div>
                    </div>
                        <Suspense fallback={ <Loader /> }>
                            <CheckoutSuccess
                            email={ email }
                            firstName={ firstname || '' }
                            lastName={ lastname || '' }
                            isEmailAvailable={ isEmailAvailable }
                            orderID={ orderID }
                            />
                        </Suspense>
                </div>
                     

            
        );
    }
    
    render() {
        return (
            <main block="Checkout">
                 <ContentWrapper
                   wrapperMix={ { block: 'Checkout', elem: 'Wrapper' } }
                   label={ __('Checkout page') }
                 >
                     <div>
                        { this.renderLoader() }
                        { this.renderContent() }
                    </div>
                 </ContentWrapper>
             </main>
        );
    }
}

export default connect(mapStateToProps) (StatusComponent);
