import {
    CheckoutTermsAndConditionsPopupComponent as SourceCheckoutTermsAndConditionsPopupComponent,
} from 'SourceComponent/CheckoutTermsAndConditionsPopup/CheckoutTermsAndConditionsPopup.component';

import './CheckoutTermsAndConditionsPopup.override.style';

/** @namespace AlmusbahblendPwa/Component/CheckoutTermsAndConditionsPopup/Component */
export class CheckoutTermsAndConditionsPopupComponent extends SourceCheckoutTermsAndConditionsPopupComponent {
    // TODO implement logic
}

export default CheckoutTermsAndConditionsPopupComponent;
