import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountForgotPasswordContainer as SourceMyAccountForgotPasswordContainer,
} from 'SourceComponent/MyAccountForgotPassword/MyAccountForgotPassword.container';

import { MyAccountPageState } from 'Component/MyAccountOverlay/MyAccountOverlay.config';

import { deleteCaptchaToken } from 'Util/Auth/CaptchaToken';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Component/MyAccountForgotPassword/Container */
export class MyAccountForgotPasswordContainer extends SourceMyAccountForgotPasswordContainer {
    // TODO implement logic
    async onForgotPasswordSuccess(form) {
        const {
            forgotPassword, setSignInState, setLoadingState, forgotPasswordEmail, isOverlayVisible,
        } = this.props;
        const submittedEmail = (form[0]).value;
       
        setLoadingState(true);
        
        try {
            await forgotPassword({ email: submittedEmail });
            setSignInState(MyAccountPageState.STATE_FORGOT_PASSWORD_SUCCESS);
            forgotPasswordEmail(submittedEmail);

            // if on route /forgotpassword
            if (!isOverlayVisible) {
                this.showSuccessNotification(submittedEmail);
            }
            setLoadingState(false);
        } catch {
            setLoadingState(false);
        } finally {
            deleteCaptchaToken();
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountForgotPasswordContainer);
