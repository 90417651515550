import {
    getStreetFields,
    getVatFields,
} from 'SourceComponent/MyAccountAddressForm/MyAccountAddressForm.form';
import { FieldType } from 'Component/Field/Field.config';
import { ValidationInputType } from 'Util/Validator/Config';

import config from './../../config/config';

export {
    getStreetFields,
    getVatFields,
};


/**
 * Returns region fields
 * @param props
 * @param events
 * @returns {[{addRequiredTag: boolean, validateOn: (string[]|*[]), validationRule: {isRequired}, options, label: *, type: string, attr: {defaultValue: number, name: string, selectPlaceholder: *}}]|*[]|[{validateOn: (string[]|*[]), validationRule: {isRequired}, label: *, type: string, attr: {defaultValue, name: string, id: string, placeholder: *}}]}
 * @namespace Component/MyAccountAddressForm/Form/getRegionFields
 */
export const getRegionFields = (
    props,
    events,
) => {
    const {
        currentRegion,
        currentRegionId,
        regionDisplayAll,
        availableRegions,
        isStateRequired,
    } = props;

    const { onRegionChange, onRegionIdChange } = events;

    if (!regionDisplayAll && !isStateRequired) {
        return [];
    }

    if (!availableRegions || !availableRegions.length) {
        return [
            {
                type: FieldType.TEXT,
                label: __('State / Province'),
                attr: {
                    id: 'address-region-id',
                    name: 'region_string',
                    value: currentRegion,
                    placeholder: __('Your state / province'),
                },
                events: {
                    onChange: onRegionChange,
                },
                addRequiredTag: isStateRequired,
                validateOn: isStateRequired ? ['onChange'] : [],
                validationRule: {
                    isRequired: isStateRequired,
                },
            },
        ];
    }

    return [
        {
            type: FieldType.SELECT,
            label: __('State / Province'),
            attr: {
                name: 'region_id',
                value: parseInt(currentRegionId),
                selectPlaceholder: __('Select region...'),
            },
            events: {
                onChange: onRegionIdChange,
            },
            options: availableRegions.map(({ id, name }) => ({ id, label: name, value: id })),
            addRequiredTag: isStateRequired,
            validateOn: isStateRequired ? ['onChange'] : [],
            validationRule: {
                isRequired: isStateRequired,
            },
        },
    ];
};


/**
* Returns address form fields
* @param props
* @param events
* @returns {[{label: *, type: string, attr: {defaultChecked, name: string}}, {label: *, type: string, attr: {defaultChecked, name: string}}, {addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue: string, name: string, placeholder: *}}, {addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue: string, name: string, placeholder: *}}, {mods: {street: boolean, multipleFields: boolean, oddAddresses: boolean}, name: string, fields: ({addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue: (*|string), name: string, placeholder: *}}[]|*[])}, null, ...*[]|{label: *, type: string, attr: {defaultValue: string, name: string}}[], null]}
* @namespace Component/MyAccountAddressForm/Form/myAccountAddressForm */
export const myAccountAddressForm = (
   props,
   events,
) => {
   const {
       default_billing: defaultBilling,
       default_shipping: defaultShipping,
       countryId,
       firstname = '',
       lastname = '',
       city = '',
       countries = [],
       postcode: zipcode = '',
       telephone = '',
       addressLinesQty = 1,
   } = props;

   const {
       onCountryChange,
       onZipcodeChange,
       onCityChange,
       onTelephoneChange,
   } = events || {};

//    console.log("Telephone Value: ", telephone);
   let phoneNumber = countryExtCodeSeperate(telephone, countryId);
//    console.log("phoneNumber Value: ", phoneNumber);

   let extensionCodes = config.extCodes;
    let countryExtCodeRow = (extensionCodes).find(ext => ext.code == countryId);
    if(!countryExtCodeRow) {
        countryExtCodeRow = extensionCodes[0];
    }
//    let countryExtCodeRow = extensionCodes.find(({ record }) => record.code === countryId);

   return [
    //    {
    //        type: FieldType.CHECKBOX,
    //        label: __('This is default Billing Address'),
    //        attr: {
    //            name: 'default_billing',
    //            defaultChecked: defaultBilling,
    //        },
    //    },
    //    {
    //        type: FieldType.CHECKBOX,
    //        label: __('This is default Shipping Address'),
    //        attr: {
    //            name: 'default_shipping',
    //            defaultChecked: defaultShipping,
    //        },
    //    },
       {
           type: FieldType.TEXT,
           label: __('First name'),
           attr: {
               name: 'firstname',
               defaultValue: firstname,
               placeholder: __('Your first name'),
           },
           addRequiredTag: true,
           validateOn: ['onChange'],
           validationRule: {
               isRequired: true,
           },
       },
       {
           type: FieldType.TEXT,
           label: __('Last name'),
           attr: {
               name: 'lastname',
               defaultValue: lastname,
               placeholder: __('Your last name'),
           },
           addRequiredTag: true,
           validateOn: ['onChange'],
           validationRule: {
               isRequired: true,
           },
       },
       {
           name: 'streetGroup',
           mods: {
               street: true,
               multipleFields: addressLinesQty > 0,
               oddAddresses: addressLinesQty % 2 === 1,
           },
           fields: getStreetFields(props),
       },
       {
           name: 'addressGroup',
           mods: { address: true },
           fields: [
               {
                   type: FieldType.SELECT,
                   label: __('Country'),
                   attr: {
                       id: 'address-country-id',
                       name: 'country_id',
                       value: countryId,
                       selectPlaceholder: __('Select country...'),
                   },
                   events: {
                       onChange: onCountryChange,
                   },
                   options: countries,
                   addRequiredTag: true,
                   validateOn: ['onChange'],
                   validationRule: {
                       isRequired: true,
                   },
               },
               ...getRegionFields(props, events),
               {
                   type: FieldType.TEXT,
                   label: __('Zip / Postal code'),
                   attr: {
                       name: 'postcode',
                       defaultValue: zipcode,
                       placeholder: __('Your zip / postal code'),
                   },
                   events: {
                       onChange: onZipcodeChange,
                   },
                   addRequiredTag: false,
                   validateOn: ['onChange'],
                   validationRule: {
                       isRequired: false,
                   },
               },
               {
                   type: FieldType.TEXT,
                   label: __('City'),
                   attr: {
                       name: 'city',
                       defaultValue: city,
                       placeholder: __('Your city'),
                   },
                   events: {
                       onChange: onCityChange,
                   },
                   addRequiredTag: true,
                   validateOn: ['onChange'],
                   validationRule: {
                       isRequired: true,
                   },
               },
               {
                    type: FieldType.TEXT,
                    label: __('Phone Country Ext.'),
                    attr: {
                        name: 'tele_ext_code',
                        value: countryExtCodeRow.emoji + countryExtCodeRow.text,
                        placeholder: countryExtCodeRow.emoji + countryExtCodeRow.text,
                        disabled: true,
                    },
                    addRequiredTag: true,
                },
                {
                    type: FieldType.TEL,
                    label: __('Phone number'),
                    attr: {
                        name: 'telephone',
                        defaultValue: phoneNumber,
                        placeholder: countryExtCodeRow.pHolder,
                    },
                    events: {
                        onChange: onTelephoneChange,
                    },
                    addRequiredTag: true,
                    validateOn: ['onChange'],
                    validationRule: {
                        inputType: countryExtCodeRow.code,
                        isRequired: true,
                    },
                },
           ],
       },
       ...getVatFields(props),
    //    {
    //         name: 'telephoneGroup',
    //         mods: { address: true },
    //         fields: [
    //            {
    //                 type: FieldType.TEXT,
    //                 label: __('Phone Country Ext.'),
    //                 attr: {
    //                     name: 'tele_ext_code',
    //                     value: countryExtCodeRow.emoji + countryExtCodeRow.text,
    //                     placeholder: countryExtCodeRow.emoji + countryExtCodeRow.text,
    //                     disabled: true,
    //                 },
    //                 addRequiredTag: true,
    //             },
    //             {
    //                 type: FieldType.TEL,
    //                 label: __('Phone number'),
    //                 attr: {
    //                     name: 'telephone',
    //                     defaultValue: phoneNumber,
    //                     placeholder: countryExtCodeRow.pHolder,
    //                 },
    //                 events: {
    //                     onChange: onTelephoneChange,
    //                 },
    //                 addRequiredTag: true,
    //                 validateOn: ['onChange'],
    //                 validationRule: {
    //                     inputType: countryExtCodeRow.code,
    //                     isRequired: true,
    //                 },
    //             },
    //         ],
    //    },
   ];
};

export const countryExtCodeSeperate = (
    phoneNumber, countryId
) => {
    let result = phoneNumber.replace(/^\++|\++$/gm,'');
    switch(countryId) {
        case "SA":
            result = phoneNumber.replace("966", "");
          break;
        case "KW":
            result = phoneNumber.replace("965", "");
          break;
        case "AE":
            result = phoneNumber.replace("971", "");
            break;
        case "BH":
            result = phoneNumber.replace("973", "");
            break;
        case "OM":
            result = phoneNumber.replace("968", "");
            break;
        case "QA":
            result = phoneNumber.replace("974", "");
            break;
        default:
            result = phoneNumber.replace("966", "");
    }

    return result;
};

export default myAccountAddressForm;
