import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountAddressFormContainer as SourceMyAccountAddressFormContainer,
} from 'SourceComponent/MyAccountAddressForm/MyAccountAddressForm.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Component/MyAccountAddressForm/Container */
export class MyAccountAddressFormContainer extends SourceMyAccountAddressFormContainer {
    // TODO implement logic
    containerFunctions = {
        onCountryChange: this.onCountryChange.bind(this),
        onZipcodeChange: this.onZipcodeChange.bind(this),
        onCityChange: this.onCityChange.bind(this),
        onRegionChange: this.onRegionChange.bind(this),
        onRegionIdChange: this.onRegionIdChange.bind(this),
        onTelephoneChange: this.onTelephoneChange.bind(this),
    };

    __construct(props) {
        super.__construct?.(props);

        this.state = {
            countryId: this.getCountry()?.value || 'US',
            availableRegions: this.getAvailableRegions() || [],
            isStateRequired: !!this.getCountry()?.is_state_required,
            currentCity: this.getCurrentAddress().city,
            currentRegion: this.getCurrentAddress().region,
            currentZipcode: this.getCurrentAddress().postcode,
            currentRegionId: this.getCurrentAddress().regionId,
            currentTelephone: this.getCurrentAddress().telephone,
        };
    }

    containerProps() {
        const {
            address,
            countries,
            defaultCountry,
            addressLinesQty,
            showVatNumber,
            regionDisplayAll,
            onSave,
        } = this.props;
        const {
            countryId,
            availableRegions,
            isStateRequired,
            currentCity,
            currentRegion,
            currentZipcode,
            currentRegionId,
            currentTelephone,
        } = this.state;

        return {
            address,
            countries,
            defaultCountry,
            addressLinesQty,
            showVatNumber,
            regionDisplayAll,
            countryId,
            availableRegions: Array.isArray(availableRegions) ? availableRegions : [],
            isStateRequired,
            onSave,
            currentCity,
            currentRegion,
            currentZipcode,
            currentRegionId,
            currentTelephone,
        };
    }

    getCurrentAddress() {
        const { address, address: { id: addressId } = {} } = this.props;

        if (!addressId) {
            return {
                region: '',
                regionId: 1,
                postcode: '',
                city: '',
                telephone: '',
            };
        }

        const { region: { region, region_id: regionId }, postcode, city, telephone } = address;

        return {
            region,
            regionId,
            postcode,
            city,
            telephone,
        };
    }

    // #Telegpne number change
    onTelephoneChange(field) {
        console.log("Entered Phone number: ", field.target.value);
        this.setState({ currentTelephone: field.target.value });
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    MyAccountAddressFormContainer,
);
