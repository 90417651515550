// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';
import Link from 'Component/Link';
import { getStoreState } from 'Util/Store';
import './CheckoutFailure.style';

/** @namespace AlmusbahblendPwa/Component/CheckoutFailure/Component */
export class CheckoutFailureComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderLoader() {
        const { isLoading } = this.props;

        return <Loader isLoading={ isLoading } />;
    }

    renderContent() {
        const {
            orderID,
            title
        } = this.props;

        const tryAgainLink = 'cart';
        const { ConfigReducer: { base_link_url = window.location.href, base_url = window.location.href } = {} } = getStoreState();
        // console.log("renderViewOrderButton Link: ", base_link_url + myOrderLink);

        return (
            <div block="Checkout" elem="Step">
                <div block="Checkout" elem="Header">
                    <div block="Checkout" elem="Title">{ title }</div>
                </div>
                <div block="CheckoutFailure">
                    <h2 className='pagetitle'>{ __('Order Payment Failed') }</h2>
                    <h3>{ __('Your order (%s) payment was failed', orderID) }</h3>
                    <p>{ __('Please contact customer support team for further updates.') }</p>

                    <div block="CheckoutFailure" elem='ButtonWrapper'>
                        <Link
                        block="Button"
                        mix={ { block: 'CheckoutFailureButton', elem: 'TryAgain' } }
                        to= {base_link_url + tryAgainLink}
                        >
                            { __('Go to Cart & Try Again') }
                        </Link>
                    </div>
                </div>
                
            </div>
        );
    }

    render() {
        
        return (
            <main block="Checkout">
            <ContentWrapper
            wrapperMix={ { block: 'Checkout', elem: 'Wrapper' } }
            label={ __('Checkout page') }
            >
                <div>
                    { this.renderLoader() }
                    { this.renderContent() }
                </div>
            </ContentWrapper>
            </main>
        );
    }
}

export default CheckoutFailureComponent;
