import {
    FieldComponent as SourceFieldComponent,
} from 'SourceComponent/Field/Field.component';

import './Field.override.style';

/** @namespace AlmusbahblendPwa/Component/Field/Component */
export class FieldComponent extends SourceFieldComponent {
    // TODO implement logic
}

export default FieldComponent;
